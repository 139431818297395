import {formatCurrency, Toast} from "../../component/utili";
import {useEffect, useState} from "react";
import apis, {api_customers, request_url} from "../../api";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {path} from "../../routes/data";
import {useStateContext} from "../../contexts/StateContext";


const ExchangeGift = () => {
    const [address, setAddress] = useState('')
    const [name, setName] = useState('')
    const [phoneCustomer, setPhoneCustomer] = useState('')

    const navigate = useNavigate();
    const {user} = useStateContext();

    const location = useLocation();




    const changeGift = async () => {

        const res = await request_url({
            url: apis.exchangeGift(),
            method: 'post',
            data: {
                phone:phoneCustomer ,
                name: name,
                address: address,
                phone_customer:  user?.phone,
                gift_id: location?.state?.id,

            }
        })
        console.log(res)
        if (res?.status) {
            Toast({type: 'success', message: 'Đổi quà thành công'})
            navigate(path.home)
            window.location.reload()
        } else {
            Toast({type: 'error', message: 'Đổi không quà thành công'})
        }
    }

    return (
        <div className={'wrap-cart'}>
            <div className='wrapp-header-title text-center py-3 sticky top-0 z-50'>
                <div className="relative">
                    <div className="text-white text-xl font-bold ">Đổi quà</div>
                    <div className="absolute top-1/2  -translate-y-1/2 px-3">
                        <Link to={path.home} className={'p-3'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16"
                                 fill="none">
                                <path
                                    d="M3.74804 6.97146L9 1.45436L7.61553 0L0 8L7.61553 16L9 14.5456L3.74804 9.02854L2.93744 8.00008L3.74804 6.97146Z"
                                    fill="#E8FDFF"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="cart-content px-3 pt-3">
                <div className="list-product-cart mt-5">


                    <div className="item-pro-cart flex gap-4 font-nunito mb-5">
                        <div className="">
                            <img src={location?.state?.image} alt={''}
                                 className={'w-[89px] h-[93px] object-cover rounded-lg border-2 border-[#CF01A4]'}/>
                        </div>
                        <div className="flex-1 ">
                            <div className={''}>
                                <div
                                    className="text-[15px] text-[#333333] font-semibold ">{location?.state?.title}
                                </div>

                                <div className="text-[15px] text-[#333333] ">{location?.state?.content}
                                </div>

                                <div className="text-[15px] text-[#333333] flex">
                                    <div className={'w-[90px]'}>Số điểm đổi:</div>
                                    {location?.state?.point}
                                </div>


                            </div>

                        </div>

                    </div>


                </div>
                <div className="">
                    <div className="text-center text-xl font-semibold">Thông tin nhận quà</div>


                    <form className="max-w-sm mx-auto">
                        <div className="mb-5">
                            <label htmlFor="name"
                                   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tên người
                                nhận</label>
                            <input type="text" id="name"
                                   value={name}
                                   onChange={(e) => setName(e.target.value)}
                                   className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                   placeholder="Nhập..." required/>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="phone"
                                   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Số điện
                                thoại</label>
                            <input type="number" id="phone"
                                   value={phoneCustomer}
                                   onChange={(e) => setPhoneCustomer(e.target.value)}
                                   className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                   required/>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="{'address'}"
                                   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Đại
                                chỉ</label>
                            <textarea id={'address'}
                                      onChange={(e) => setAddress(e.target.value)}
                                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                      required>{address}</textarea>
                        </div>

                        <div className="text-center">
                            <button type="button"
                                    onClick={changeGift}
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Đổi quà
                            </button>
                        </div>
                    </form>

                </div>


            </div>
            <div className=" pb-[120px]"></div>
            {/*<div className="btn-payment fixed bottom-0 left-0 w-full h-[120px] font-nunito">*/}
            {/*    <div className="d-flex h-full grid grid-cols-2 items-center justify-between px-3 py-3">*/}
            {/*        <div className="">*/}

            {/*            <div*/}
            {/*                onClick={changeGift}*/}
            {/*                className={'text-[15px] font-semibold text-white text-center bg-[#D701AA] rounded-lg py-2 mt-2'}*/}
            {/*            >Đổi quà*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="text-right">*/}
            {/*            <div className="text-[15px]">{address?.first_name} {address?.last_name}</div>*/}
            {/*            <div*/}
            {/*                className={'text-[#828282] text-sm'}>{address?.address}, {address?.province_name}, {address?.district_name}, {address?.ward_name}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}


            {/*</div>*/}

        </div>
    )

}
export default ExchangeGift;