import HeaderTitle from "../../component/header_title";
import SearchInput from "../../component/searchInput";
import PostBaby from "../../component/post_baby/PostBaby";
import {useEffect, useState} from "react";
import apis, {api_customers, request_api, request_url} from "../../api";

function ChildCareLibrary() {
    const [articles, setArticles] = useState([]);
    const [selectedArticleId, setSelectedArticleId] = useState('');
    const [key, setKey] = useState('');

    const [posts, setPosts] = useState([]);

    const fetchArticles = async () => {
        try {
            const response = await fetch(api_customers.getListArticle());
            const data = await response.json();
            setArticles(data?.categories || []);
            setSelectedArticleId(data?.categories[0]?.id || '');
        } catch (error) {
            console.error("Error fetching articles:", error);
        }
    };

    const fetchPostsByCategory = async (categoryId) => {
        try {
            const response = await request_api({
                url: apis.getPostByCategory(),
                method: 'POST',
                data: { category_id: categoryId }
            });


            setPosts(response?.posts);
            console.log(response?.posts)
        } catch (error) {
            console.error("Error fetching posts:", error);
        }
    };
    const searchArticles = async (key) => {
        if (key) {
            try {
                let data = new FormData();
                data.append('key', key);
                const response = await request_url({
                    url:apis.searchArticlesByName(),
                    method: 'post',
                    data:data
                });
                if (response.status){
                    setPosts(response?.articles);
                }
            } catch (error) {
                console.error("Error searching articles:", error);
            }
        } else {
        }
    }


    useEffect(() => {
        fetchArticles();
    }, []);

    useEffect(() => {
            fetchPostsByCategory(selectedArticleId);
    }, []);

    const  handleChange = (id) => {
        setSelectedArticleId(id)
        fetchPostsByCategory(id);
    }




    return (
        <div className={'wrapp-child-cảe'}>
            <HeaderTitle title={'Thư viện chăm con'}/>
            <div className="px-5 mt-3">
                <SearchInput onSubmit={(value) => searchArticles(value)} placeholder={'Nhập từ khóa tìm kiếm'}/>
                <div className="mt-3 font-nunito text-base font-semibold">
                    Danh mục sức khoẻ cho bé
                </div>
                <div className="mt-3">
                    <div className="buy-subcategories rounded-lg   flex flex-wrap gap-2 mt-5  ">
                        {
                            articles?.map((article, index) => (
                                <div key={index}
                                     className={`subcate-item text-xs  text-white bg-[#960478] inline-block px-5 py-2 rounded-full   ${selectedArticleId === article.id ? ' ' : 'bg-[#960478] opacity-70'}`}
                                     onClick={() =>handleChange(article.id)}>{article?.name}</div>
                            ))
                        }

                    </div>

                </div>
                <div className="mt-3">
                    {
                        posts?.map((post, i) => (
                            <div key={i} className={'mb-5'}><PostBaby title={post?.title} id={post?.id} image={post?.image} content={post?.content} category_name={post?.category_name} /></div>
                        ))
                    }

                </div>
            </div>

        </div>
    )
}

export default ChildCareLibrary