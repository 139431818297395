import {image} from "../../assets/image";
import {Link, useNavigate} from "react-router-dom";
import {data} from "../../constants/data";
import PostBaby from "../../component/post_baby/PostBaby";
import {path} from "../../routes/data";
import {useEffect, useState} from "react";
import {useStateContext} from "../../contexts/StateContext";
import apis, {request_url, url} from "../../api";
import ProductByCategory from "./ProductByCategory";
import {Toast} from "../../component/utili";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
function Home() {
    const [keySearch, setkeySearch] = useState(null)
    const [dataHome, setDataHome] = useState();
    const navigate = useNavigate();

    const { user, point} = useStateContext();


    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(path.result_search, {state: {keySearch: keySearch}})
    };


    const getDataHome = async () => {
      const res = await request_url({url:apis.getDataHome(), method: 'GET'});
        setDataHome(res)
    }
    useEffect(() => {
        getDataHome();
    }, []);
    return (
        <div className="wrapper-home">
            <div className="home-head relative">
                <div className="home-head-content py-5 px-3">
                    <div className="flex gap-3 items-center">
                        {user?.avatar && user.avatar !== null && user.avatar !== '' ? (
                            <img src={url + user.avatar}
                                 className="avatar-home object-cover size-[45px] rounded-full" alt=""/>
                        ) : (
                            <img src={image.avatar_default}
                                 className="avatar-home object-cover size-[45px] rounded-full" alt=""/>
                        )}

                        <div className="avatar-info text-white">
                            <div className="info-name text-sm font-medium">{user?.first_name} {user?.last_name}</div>
                            <div className="info-point text-xs flex gap-1 font-normal">Điểm
                                tích: <strong>{point}</strong> điểm <span><img src={image.icon_point} alt=""/></span>
                            </div>
                        </div>
                        <div className="ms-auto">
                            <Link to={path.cart}>
                                <img src={image.icon_cart} className="avatar>" alt={''}/>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="head-banner absolute bottom-0 ">
                    <Swiper
                        loop={true}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay]}
                        className="mySwiper"
                    >
                        {
                            dataHome?.banners?.map((item, index)=>(
                                <SwiperSlide key={index} className={''} >
                                    <img src={url +item.image} alt="" className="w-full h-[115px] object-cover rounded-lg"/>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>

                </div>
            </div>
            <div className="home-content">
                <div className="list-menu-home p-5">
                    {
                        data.map((item, index) => (
                            item.active === 1 ? (
                                <Link to={item.path} className="menu-item text-center flex flex-col  items-center"
                                      key={index}>
                                    <img src={item.icon} alt="" className='rounded-full size-[50px]'/>
                                    <div className="mt-3">{item.name}</div>
                                </Link>
                            ) : (
                                <div className="menu-item text-center flex flex-col  items-center"
                                     key={index} onClick={()=>Toast({type:'warning',message:'Tính năng đang phát triển!'})}>
                                    <img src={item.icon} alt="" className='rounded-full size-[50px]'/>
                                    <div className="mt-3">{item.name}</div>
                                </div>
                            )

                        ))
                    }
                </div>
                <div className="form-search-product px-3">
                    <form className={''} onSubmit={handleSubmit}>
                        <div className="form-search-content flex items-center py-3 px-3">
                            <input className="" placeholder={'Tìm kiếm sản phẩm'} name={'seacrh'}
                                   onChange={(e) => setkeySearch(e.target.value)}/>
                            <button>
                                <img src={image.icon_search} alt="" className=""/>
                            </button>
                        </div>
                    </form>
                </div>


                <div>
                    {
                        dataHome?.products?.map((item, index) => (
                            <ProductByCategory key={index} category={item}/>
                        ))
                    }
                </div>


                <div className="mt-5 px-5">
                    <div className="flex justify-between">
                        <div className="title-cate text-base font-semibold">
                            Thư viện chăm con
                        </div>
                        <Link to={path.child_care_library} className="text-sm font-light">Tất cả</Link>
                    </div>

                </div>
                <div className="mt-3 px-5">
                    {
                        dataHome?.articles?.map((item, i) => (
                            <div className={'mb-5'} key={i}>
                                <PostBaby key={i} id={item?.id} title={item?.title} image={item?.image}
                                          content={item?.content} category_name={item?.category_name}/>
                            </div>

                        ))
                    }
                </div>


            </div>
        </div>
    );
}

export default Home;