import {useState} from "react";
import Swal from "sweetalert2";
import {api_customers, request_res, request_url} from "../../api";
import {useStateContext} from "../../contexts/StateContext";

function Login() {

    const [phone, setPhone] = useState('')
    const {setUser}=useStateContext();
    const handleLogin =async () => {
        if (/^\d{10}$/.test(phone)) {
            const data = new FormData();
            data.append('phone', phone);

            try {
                    const response = await request_res({url: api_customers.login(), method: 'POST', data});
                    if (response?.status) {
                        localStorage.setItem('token_customer', response?.token);
                        localStorage.setItem('phone', phone);
                        localStorage.setItem('user', response?.user);
                        setUser(response?.user);
                        window.location.href='/'
                    }
                    console.log(response);
            } catch (error) {
                console.log(error);
                Swal.fire({
                    position: "top",
                    icon: "error",
                    title: "Đăng nhập không thành công!",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } else {
            Swal.fire({
                position: "top",
                icon: "error",
                title: "Số điện thoại không hợp lệ",
                showConfirmButton: false,
                timer: 1500
            });
        }
    }


    return (
        <div className='w-full h-full bg-gray-500 min-h-[100vh] flex flex-col justify-center items-center'>
            <div className="w-full  px-3">
                <div className="w-full py-9 bg-white px-3 rounded  shadow shadow-blue-100">
                    <div className='text-center mb-3 text-2xl font-bold'>Đăng nhập</div>
                    {/*<form onSubmit={handleLogin}>*/}
                        <div className='w-full   gap-2 '>
                            <div className=' bg-white rounded flex-grow '>
                                <input id="email" name="phone" type="number" autoComplete="email" required
                                       className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                       onChange={(e) => setPhone(e.target.value)}
                                       placeholder={'Nhập số điện thoại'}/>
                            </div>
                            <div className={'mt-3 text-center'}>
                                <button className='rounded bg-emerald-600 text-white px-3 py-2' onClick={handleLogin}>Đăng nhập</button>
                            </div>
                        </div>
                    {/*</form>*/}
                </div>
            </div>
        </div>

    );

}

export default Login;