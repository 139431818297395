import { image } from "../../assets/image";
import {useEffect, useState} from "react";

const SearchInput = ({ placeholder, onSubmit ,keySearch}) => {
    const [search, setSearch] = useState("");
    useEffect(()=>{
        setSearch(keySearch);
    },[keySearch]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        onSubmit(search);
    };

    return (
        <div className="form-search-product">
            <form onSubmit={handleFormSubmit}>
                <div className="form-search-content flex items-center py-3 px-3">
                    <input
                        className=""
                        placeholder={placeholder}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button type="submit">
                        <img src={image.icon_search} alt="Search" className="" />
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SearchInput;
