import HeaderTitle from "../header_title";
import {image} from "../../assets/image";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import apis, {request_api} from "../../api";
import {url} from '../../api/index'

const PostBabyDetail = () => {
    const {id} = useParams();
    const [post, setPost] = useState()
    const [postId, setPostId] = useState('');
    const getPostDetail = async (id) => {
        try {
            const response = await request_api({url: apis.getPostDetail(id), method: 'get'});
            if (response && response.post) {
                console.log(response?.post);
                setPost(response?.post);
            } else {
                console.error("Error: Product data not found in response");
            }
        } catch (error) {
            console.error("Error fetching product details:", error);
        }
    };
    useEffect(() => {
        setPostId(id);
    }, [id]);
    useEffect(() => {
        getPostDetail(postId);
    }, [postId]);


    return (
        <div>
            <HeaderTitle title="Chi tiết bài viết"/>
            <div className="p-5">
                <div className="text-sm font-sans font-medium text-[#06070C]">{post?.title}</div>
                <div className="w-full relative">
                    <img src={url + post?.image} alt=""
                         className='absolute w-full h-full object-cover rounded-lg top-0 left-0'/>
                    <div className="pt pt-[50%]"></div>
                </div>
                <div className="mt-3 text-xs font-sans">
                    <div dangerouslySetInnerHTML={{__html: post?.content}}/>

                </div>

            </div>
        </div>
    )
}

export default PostBabyDetail;