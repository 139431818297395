import HeaderTitle from "../../component/header_title";
import {Link} from "react-router-dom";
import ItemVoucher from "./ItemVoucher";
import {useStateContext} from "../../contexts/StateContext";

function MyVoucher() {
    const {changedVoucher}=useStateContext();
    return (
        <div className={''}>
            <HeaderTitle title={'Voucher của tôi'}/>
            <div className="list-vouchers mt-5 px-5">
                <div className="mb-3">
                    <div className="flex items-center">
                        <div className="text-baseb font-semibold">Voucher đã</div>
                        <Link to={''} className={'ms-auto text-sm font-light'}>Tất cả</Link>

                    </div>
                    <div className="mt-3">
                        {
                            changedVoucher?.map((item,idx)=>(
                                <ItemVoucher key={idx} title={item?.title} content={item?.content} date={''} image={item?.image}/>
                            ))
                        }
                    </div>


                </div>
            </div>

        </div>
    )
}
export default MyVoucher;