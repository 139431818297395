import HeaderTitle from "../../component/header_title";
import {useEffect, useState} from "react";
import apis, {request_api} from "../../api";

function Point(){
    const [pointContent, setPointContent] = useState()
    const getData=  async () =>{
        const res = await request_api({url: apis.point_content(), method: 'GET'})
        if(res?.status){
            setPointContent(res?.data)
        }
    }
    useEffect(() => {
        getData()
    }, []);
    return(
        <div>
            <HeaderTitle title={'Tích điểm'}/>
            <div className='mt-3 px-3 pb-10'>

                <div dangerouslySetInnerHTML={{ __html:pointContent?.content }} />


            </div>

        </div>
    )

}
export  default Point;