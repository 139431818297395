import HeaderTitle from "../../component/header_title";
import {useEffect, useState} from "react";
import apis, {request_api} from "../../api";

function Policy(){
    const [pointContent, setPointContent] = useState()
    const getData=  async () =>{
        const res = await request_api({url: apis.policy_content(), method: 'GET'})
        if(res?.status){
            setPointContent(res?.data)
        }
    }
    useEffect(() => {
        getData()
    }, []);
    return(
        <div>
            <HeaderTitle title={'Điều khoản và chính sách'}/>
            <div className='mt-3 px-3 pb-10'>

                <div dangerouslySetInnerHTML={{ __html:pointContent?.content }} />


            </div>

        </div>
    )

}
export  default Policy;