import HeaderTitle from "../../component/header_title";
import { path } from "../../routes/data";
import { useState } from "react";
import { api_customers, request_url } from "../../api";
import Swal from "sweetalert2";
import { useStateContext } from "../../contexts/StateContext";

const UpdateCustomer = () => {
    const { user, setUser } = useStateContext();
    const [firstName, setFirstName] = useState(user?.first_name || '');
    const [lastName, setLastName] = useState(user?.last_name || '');
    const [phone, setPhone] = useState(user?.phone || '');
    const [file, setFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!firstName || !phone) {
            Swal.fire({
                position: "top",
                icon: "error",
                title: "Vui lòng nhập đầy đủ thông tin",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        const formData = new FormData();
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('phone', phone);
        if (file) {
            formData.append('avatar', file);
        }

        try {
            const res = await request_url({ url: api_customers.updateCustomer(), method: 'POST', data: formData });
            if (res?.status) {
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Cập nhập thành công!",
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    window.location.href = path.account;
                });
                setUser(res.user);
            } else {
                throw new Error('Cập nhập thất bại!');
            }
        } catch (error) {
            Swal.fire({
                position: "top",
                icon: "error",
                title: error.message,
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    const handleDelete = () => {
        setSelectedImage(null);
        setFile(null);
    };

    return (
        <div>
            <HeaderTitle title="Cập nhật thông tin tài khoản" />
            <div className="mt-5 px-3">
                <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
                    {selectedImage ? (
                        <div className="flex justify-center relative">
                            <img src={selectedImage} alt="Selected" className="object-contain" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                            <div className="absolute top-0 right-0 bg-fuchsia-300 font-bold px-2 py-1 rounded text-sm cursor-pointer" onClick={handleDelete}>Xóa</div>
                        </div>
                    ) : (
                        <div className="flex items-center justify-center w-full mb-3">
                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-28 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                                <input id="dropzone-file" type="file" className="hidden" onChange={handleImageChange} accept="image/*" />
                            </label>
                        </div>
                    )}
                    <div className="relative z-0 w-full mb-5 group">
                        <input type="text" id="first_name" value={firstName} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " onChange={(e) => setFirstName(e.target.value)} required />
                        <label htmlFor="first_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Họ</label>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                        <input type="number" id="phone" value={phone} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " onChange={(e) => setPhone(e.target.value)} required />
                        <label htmlFor="phone" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Số điện thoại</label>
                    </div>
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default UpdateCustomer;
