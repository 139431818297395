import HeaderTitle from "../../component/header_title";
import {useEffect, useState} from "react";

import api, {request_url} from "../../api";
import {useStateContext} from "../../contexts/StateContext";
import ItemGift from "./itemGift";

import {path} from "../../routes/data";
import {useNavigate} from "react-router-dom";

const Gift = () => {
    const [dataGift, setDataGift] = useState([])
    const {point} = useStateContext();
    const  navigate = useNavigate();

    const getDataGift = async () => {
        const res = await request_url({url: api.getListGift(), method: 'get'});
        if (res?.status) {
            console.log(res?.data);
            setDataGift(res?.data)
        }
    }

    useEffect(() => {
        getDataGift();
    }, []);

    const navHistoryGift = () => {
        navigate(path.history_gift)
    }


    return (
        <div className={'wrap-voucher'}>
            <HeaderTitle title={'Đổi quà'}/>
            <div className="voucher-content mt-3 px-5">
                <div className="banner-voucher text-white p-3 font-nunito">
                    <div className="flex">
                        <div className="w-[70%]">
                            <div
                                className="inline-block text-nowrap px-3 py-1 bg-[#9A0079]/50 rounded text-[11px] font-bold">
                                {/*30 tháng 4,2024*/}
                            </div>
                            <div className="text-base font-extrabold">
                                Mở khoá những voucher
                                hấp dẫn cùng Lê Uyên
                            </div>
                        </div>
                        <div className="w-[40%] ps-3">
                            <div className="text-sm font-bold text-[#E8FDFF]">Bạn đang có</div>
                            <div className="">
                                <span className={'text-[40px] text-[#FFE600] font-bold'}>{point}</span>
                                <span className={'text-sm font-bold ms-2'}>điểm</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="flex justify-end mt-5">

                </div>
                <div className="list-vouchers mt-5">
                    <div className="mb-3">
                        <div className="flex items-center">
                            <div className="text-baseb font-semibold">Danh sách quà tặng</div>
                            <div
                                onClick={navHistoryGift}
                                className=" ms-auto text-sm text-white font-semibold bg-green-500 px-2 rounded">Lịch sử đổi
                                quà
                            </div>
                        </div>
                        <div className="mt-3">
                            {
                                dataGift?.map((item, idx) => (
                                    <ItemGift quantity={item.quantity} point={item.point} key={idx} title={item?.name}
                                              content={item?.description} id={item?.id} image={item?.image}/>
                                ))
                            }
                        </div>


                    </div>
                </div>


            </div>

        </div>
    )

}
export default Gift;