import {image} from "../assets/image";
import { v4 as uuidv4 } from 'uuid';
import {path} from '../routes/data'
const data = [
    {
        'name': 'Tích điểm',
        'path': path.point,
        'icon':image.icon_tich_diem,
        'active':1

    }, {
        'name': 'Mua sắm',
        'path': path.buy,
        'icon':image.icon_mua_sam,
        'active':1
    }, {
        'name': 'Tìm sản phẩm',
        'path': path.result_search,
        'icon':image.icon_tim_san_pham,
        'active':1

    }, {
        'name': 'Tư vấn',
        'path': 'tel:0974942777',
        'icon':image.icon_tu_van,
        'active':1

    }, {
        'name': 'Voucher',
        'path': path.voucher,
        'icon':image.icon_voucher,
        'active':1
    }, {
        'name': 'Hạng thành viên',
        'path': path.class_member,
        'icon':image.icon_quyen_loi,
        'active':1

    }, {
        'name': 'Lịch sử mua',
        'path': path.history_buy,
        'icon':image.icon_buy_history,
        'active':1

    },
    {
        'name': 'Phản hồi',
        'path': 'https://zalo.me/322237938687600710',
        'icon':image.icon_phan_hoi,
        'active':1

    },
]

const  dataCategory=[
    {
        'id':uuidv4(),
        'name':'Sữa',
        listSubItems:[
            {
                'id':uuidv4(),
                'name':'Sữa bột',
            },
            {
                'id':uuidv4(),
                'name':'Sữa đặc',
            }
        ]

    },
    {
        'id':uuidv4(),
        'name':'Sữa',
        listSubItems:[
            {
                'id':uuidv4(),
                'name':'Sữa bột',
            },
            {
                'id':uuidv4(),
                'name':'Sữa đặc',
            },
        ]

    },

]

const dataPage=[
    {
        name:'Trang chủ',
        image:image.home,
        image_active:image.home_active,
        path:path.home,
    },
    {
        name:'Mua sắm',
        image:image.bag,
        image_active:image.buy_active,
        path:path.buy
    },
    {
        name:'',
        image:'',
        path:''
    },
    {
        name:'Thông báo',
        image:image.thong_bao,
        image_active:image.notification_active,
        path:path.notifications
    },
    {
        name:'Tài khoản',
        image:image.account,
        image_active:image.profile_active,
        path:path.account
    },

]
export {data,dataCategory,dataPage}