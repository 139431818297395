import HeaderTitle from "../../component/header_title/index";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import apis, {request_api} from "../../api";
import {formatCurrency, Toast} from "../../component/utili";
import {path} from "../../routes/data";
import {useStateContext} from "../../contexts/StateContext";

const ProductDetail = () => {
    const {id} = useParams();
    const [productId, setProductId] = useState('');
    const [product, setProduct] = useState('')
    const [quantity, setQuantity] = useState(0)
    const {setProductInCart, productInCart} = useStateContext();
    const navigate = useNavigate();

    const getQuantity = () => {
        if (productId !== null && productId !== undefined && productId !== '') {
            let storedProducts = [];
            storedProducts = JSON.parse(localStorage.getItem('carts')) || [];
            if (storedProducts) {
                const foundProduct = storedProducts.find(item => item.product_id == productId);
                if (foundProduct) {
                    setQuantity(foundProduct.quantity);
                } else {
                    setQuantity(0);
                }
            } else {
                setQuantity(0);
            }
        }
    }

    useEffect(() => {
        getQuantity();

    }, [productId]);




    useEffect(() => {
        const storedProducts = JSON.parse(localStorage.getItem('carts'));
        if (storedProducts) {
            const foundProduct = storedProducts.find(item => item.product_id == productId);
            if (foundProduct) {
                setQuantity(foundProduct.quantity);
            } else {
                setQuantity(0);
            }
        } else {
            setQuantity(0);
        }
    }, [productInCart]);



    useEffect(() => {
        if (productId!==null && productId!==undefined && productId!==''){
            const storedProducts = JSON.parse(localStorage.getItem('carts'));
            if (storedProducts) {
                const foundProduct = storedProducts.find(item => item.product_id == productId);
                if (foundProduct) {
                    setQuantity(foundProduct.quantity);
                } else {
                    setQuantity(0);
                }
            } else {
                setQuantity(0);
            }
        }
    }, [productId]);


    const getDataProductDetails = async (productId) => {
        try {
            const data = new FormData();
            data.append('id', productId);
            const response = await request_api({url: apis.getProductDetail(), method: 'post', data: data});
            if (response && response?.data?.product) {
                console.log(response.data?.product);
                setProduct(response.data?.product);
            } else {
                console.error("Error: Product data not found in response");
            }
        } catch (error) {
            console.error("Error fetching product details:", error);
        }
    };
    useEffect(() => {
        setProductId(id);

    }, [id]);

    useEffect(() => {
        if (productId && productId !== '') {
            getDataProductDetails(productId);

        }

    }, [productId])

    const clickBuy= ()=>{
        handleCartProduct(productId).then(()=>{
            navigate(path.cart)
        });

    }
    const handleCartProduct = async (id) => {

        try {
            const data = new FormData();
            data.append('id', id);
            const res = await request_api({url: apis.getProductDetail(), method: 'post', data: data});
            const product = {
                product_id: res?.data?.product?.id,
                product_name: res?.data?.product?.name,
                product_image: res?.data?.product?.image?.src,
                product_price: res?.data?.product?.variants[0]?.price,
                variant_id: res?.data?.product?.variants[0]?.id,
                name: res?.product?.data?.name,
                quantity: 1
            };

            let storedProducts = [];
            storedProducts = JSON.parse(localStorage.getItem('carts')) || [];

            const existingProductIndex = storedProducts.findIndex(item => item.product_id === product.product_id);

            if (existingProductIndex !== -1) {
                storedProducts[existingProductIndex].quantity++;
            } else {
                storedProducts.push({...product, quantity: 1});
            }

            setProductInCart(storedProducts);
            localStorage.setItem('carts', JSON.stringify(storedProducts));
            Toast({type: 'success', message: 'Thêm vào giỏ hàng thành công'});

        } catch (error) {
            console.error('Error fetching product details:', error);
            handleCartProduct(productId)
        }
    };
    const handleChangeQuantity = (type, id) => {
        try {
            let storedProducts = [];
            storedProducts = JSON.parse(localStorage.getItem('carts'));
            const existingProductIndex = storedProducts.findIndex(item => item.product_id == id);

            if (existingProductIndex !== -1) {
                if (type === 'increase') {
                    storedProducts[existingProductIndex].quantity++;
                    Toast({type: 'success', message: 'Cập nhật thành công!'});
                } else if (type === 'decrease') {
                    if (storedProducts[existingProductIndex].quantity > 1) {
                        storedProducts[existingProductIndex].quantity--;
                    } else {
                        Toast({type: 'error', message: 'Số lượng không được nhỏ hơn 1'})
                    }
                }
                setProductInCart(storedProducts);
                localStorage.setItem('carts', JSON.stringify(storedProducts));

            } else {
                handleCartProduct(productId)
            }
        } catch (error) {
            console.error('Error handling quantity change:', error);
        }
    };

    return (
        <div className="wrapp-pro-detail relative pb-[150px] ">
            <HeaderTitle title={"Chi tiết sản phẩm"}/>
            <div className='pro-detail-content px-3'>
                <div className="text-[15px] font-semibold text-black">{product.name}
                </div>
                <div className="">
                    <div className="">
                        <img src={product?.image?.src} alt="" className={'w-full  object-contain'}/>
                    </div>
                </div>
                <div className="product-info mt-3">
                    <div className="flex items-center ">
                        <div className="flex items-center font-nunito text-[15px] font-semibold">Giá bán:
                            <strong
                                className={'ms-3 text-[#FE3A30]'}>{product?.variants && product.variants.length > 0 ? formatCurrency(product.variants[0]?.price) : ''}</strong>
                            {/*<del className={'ms-5 text-[8px] font-dm-sans'}>295.000đ</del>*/}
                        </div>
                        <span className="ms-auto me-3 p-2" onClick={() => handleCartProduct(product.id)}> <svg
                            xmlns="http://www.w3.org/2000/svg" width="20"
                            height="20" viewBox="0 0 15 15" fill="none">
  <path fillRule="evenodd" clipRule="evenodd"
        d="M14.5527 2.54813C14.3725 2.33715 14.1469 2.16747 13.8919 2.05113C13.6368 1.9348 13.3587 1.87466 13.0771 1.875H2.71794L2.69103 1.65562C2.63596 1.19964 2.41129 0.779208 2.0596 0.474049C1.70791 0.16889 1.25366 0.000213621 0.782963 0H0.640723C0.470792 0 0.307822 0.065848 0.187663 0.183058C0.0675045 0.300269 0 0.45924 0 0.625C0 0.79076 0.0675045 0.949732 0.187663 1.06694C0.307822 1.18415 0.470792 1.25 0.640723 1.25H0.782963C0.939897 1.25002 1.09137 1.30622 1.20864 1.40795C1.32592 1.50967 1.40084 1.64984 1.4192 1.80187L2.30083 9.11438C2.39236 9.87487 2.76695 10.5761 3.35348 11.085C3.94002 11.5939 4.69768 11.875 5.48266 11.875H12.1737C12.3437 11.875 12.5066 11.8092 12.6268 11.6919C12.7469 11.5747 12.8145 11.4158 12.8145 11.25C12.8145 11.0842 12.7469 10.9253 12.6268 10.8081C12.5066 10.6908 12.3437 10.625 12.1737 10.625H5.48266C5.08609 10.6239 4.69957 10.5032 4.37609 10.2794C4.05262 10.0556 3.80801 9.73972 3.67583 9.375H11.3132C12.0644 9.37504 12.7916 9.11764 13.3679 8.64777C13.9442 8.1779 14.333 7.52544 14.4662 6.80438L14.9692 4.08313C15.0194 3.81313 15.0081 3.53567 14.9362 3.2704C14.8642 3.00513 14.7333 2.75855 14.5527 2.54813ZM13.7115 3.86125L13.2079 6.5825C13.1278 7.01562 12.8942 7.4075 12.5478 7.6895C12.2014 7.9715 11.7644 8.12567 11.3132 8.125H3.47208L2.86916 3.125H13.0771C13.1713 3.12445 13.2644 3.14414 13.3498 3.18266C13.4352 3.22118 13.5109 3.27759 13.5715 3.34788C13.632 3.41817 13.676 3.50061 13.7002 3.58934C13.7244 3.67806 13.7282 3.7709 13.7115 3.86125ZM4.48506 15C5.19278 15 5.7665 14.4403 5.7665 13.75C5.7665 13.0596 5.19278 12.5 4.48506 12.5C3.77734 12.5 3.20361 13.0596 3.20361 13.75C3.20361 14.4403 3.77734 15 4.48506 15ZM12.1737 13.75C12.1737 14.4403 11.6 15 10.8923 15C10.1846 15 9.61084 14.4403 9.61084 13.75C9.61084 13.0596 10.1846 12.5 10.8923 12.5C11.6 12.5 12.1737 13.0596 12.1737 13.75Z"
        fill="#502569"/>
</svg></span>
                    </div>
                    <div className="mt-3 text-lg font-bold">Mô tả sản phẩm</div>
                    {/*<div className="text-[13px] font-nunito font-bold ">Thực phẩm tăng cường đề kháng Biostime 2′-FL HMO*/}
                    {/*    ProBoost + Vit D 44.8g – Đề kháng tốt, bé vui khỏe mẹ yên tâm*/}
                    {/*</div>*/}
                    <div className="text-sm font-nunito font-normal mt-3 px-2">
                        <div dangerouslySetInnerHTML={{__html: product.content}}/>
                    </div>
                </div>
            </div>
            <div className="fixed  bottom-0 w-full bg-[#FFD9F7] p-2 max-w-[768px]  m-auto  ">
                <div className={'flex items-center gap-3'}>
                    <img src={product?.image?.src} alt={''} className={' w-[30%] h-[100px] object-contain'}/>
                    <div>
                        <div className="">{product?.name}</div>
                        <div className="">
                            <div className="">
                                <div className="flex  rounded-lg h-[26px]  ">
                                                <span
                                                    className={'bg-[#E97BF2] px-3 rounded-l-lg text-[24px]   border-2 border-[#BE37A0] flex items-center border-r-0'}
                                                    onClick={() => handleChangeQuantity('decrease', productId)}>-</span>
                                    <span
                                        className={'w-[50px] text-center text-[17px] flex items-center justify-center  border-2 border-[#FB01C4] '}>{quantity}</span>
                                    <span
                                        className={'bg-[#E97BF2] px-3 rounded-r-lg text-[24px] border-2 border-[#BE37A0] flex items-center border-l-0'}
                                        onClick={() => handleChangeQuantity('increase', productId)}>+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-nowrap  font-bold">
                        <div className="bg-[#E301B3] inline-block text-white px-3 py-1 rounded-md" onClick={() =>clickBuy()}>Mua ngay</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetail;