import HeaderTitle from "../../component/header_title";
import SearchInput from "../../component/searchInput";
import Product_Item from "../../component/product_item";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import apis, {request_api} from "../../api";

const ResultSearch = () => {
    const location = useLocation();

    const [keySearch, setKeySearch] = useState(location?.state?.keySearch || '');
    const [products, setProducts] = useState([])

    const getProductsData = async () => {
        const data = new FormData();
        data.append('name', keySearch)
        const response = await request_api({url: apis.getProductSearch(), method: 'post', data: data});
        setProducts(response?.data?.products);

    }
    useEffect(() => {
        if (keySearch != null) {
            getProductsData(keySearch);
        }
    }, [keySearch]);


    return (
        <div>
            <HeaderTitle title={'Kết quả tra cứu sản phẩm'}/>
            <div className={'px-5 mt-3'}>
                <SearchInput placeholder={'Tìm kiếm sản phẩm'} onSubmit={(value) => setKeySearch(value)}
                             keySearch={keySearch}/>
            </div>
            <div className="mt-5 px-8 grid grid-cols-2 gap-3">
                {products?.map((item, index) => (
                    item?.image && item?.image.src !== '' && item?.image?.src !== null && (
                        <div key={index} className=""><Product_Item product={item}/></div>)
                ))}
            </div>
        </div>
    )
}
export default ResultSearch;