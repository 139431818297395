import {useStateContext} from "../../contexts/StateContext";
import {calculateTotalPrice, formatCurrency, Toast} from "../../component/utili";
import {useEffect, useState} from "react";
import  {api_customers, request_url} from "../../api";
import Swal from "sweetalert2";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {path} from "../../routes/data";

const Cart = () => {
    const [address, setAddress] = useState('')
    const [totalPrice, setTotalPrice] = useState('');
    const [voucherDetail, setVoucherDetail] = useState('')
    const [moneyReduced, setMoneyReduced] = useState('')
    const [voucherId, setVoucherId] = useState('')
    const navigate = useNavigate();

    const {setSystemVouchers, setChangedVoucher, setVocherRanks, setPoint} = useStateContext();

    const {productInCart, setProductInCart} = useStateContext();

    useEffect(() => {
        console.log(productInCart);
    }, []);

    const location = useLocation();
    const getAddressID = async (id) => {
        const res = await request_url({url: api_customers.getAddressId(id), method: 'get'})
        setAddress(res?.data)
    }
    const getDataVoucherDetail = async (id) => {
       if (id !=='' && id !==null){
           const res = await request_url({url: api_customers.voucherCustomerDetail(id), method: 'get'})
           if (res.status) {
               if (totalPrice <= res?.voucher?.total_money_min) {
                   Toast({type: 'warning', message: `Đơn hàng không đạt giá trị tối thiểu`})
                   setVoucherId('');
               } else {
                   setVoucherDetail(res?.voucher)
                   if (res?.voucher?.type === "discount") {
                       console.log(res?.voucher?.total_money_min)
                       setMoneyReduced(res?.voucher?.value)
                   } else if (res?.voucher?.type === "percentage_discount") {
                       if (res?.voucher?.value / 100 * totalPrice > res?.voucher?.max_money) {
                           setMoneyReduced(res?.voucher?.max_money)
                       }else {
                           setMoneyReduced(res?.voucher?.value / 100 * totalPrice)
                       }
                   } else if (res?.voucher?.type === "free_shipping") {
                       setMoneyReduced(res?.voucher?.value)
                   }

               }


           }
       }
    }

    const handleOrder = async () => {
        if (address === undefined || address === '' || address === null) {
            Swal.fire({
                icon: 'error',
                text: 'Bạn chưa chọn địa chỉ giao hàng',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                navigate(path.create_address)
            });

        } else if (productInCart.length < 1) {
            Swal.fire({
                icon: 'error',
                text: 'Bạn chưa chọn sản phẩm',
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                navigate(path.buy)
            });
        } else {
            const data = new FormData();
            const fullAddress = `${address?.address}, ${address?.ward_name}`;
            data.append('province_name', address?.province_name);
            data.append('district_name', address?.district_name);
            data.append('address', fullAddress);
            data.append('phone', address?.phone);
            const customerName = `${address?.first_name} ${address?.last_name}`;
            data.append('name', customerName);
            data.append('voucher_id', voucherDetail?.id > 0 ? voucherDetail?.id : '');
            productInCart.forEach((product, index) => {
                data.append(`data[${index}][product_name]`, product.product_name);
                data.append(`data[${index}][product_image]`, product.product_image);
                data.append(`data[${index}][product_price]`, product.product_price);
                data.append(`data[${index}][quantity]`, product.quantity);
                data.append(`data[${index}][variant_id]`, product.variant_id);
            });
            try {
                const res = await request_url({
                    url: api_customers.order(),
                    method: 'post',
                    data: data
                });
                if (res?.status) {
                    Swal.mixin({
                        toast: true,
                        position: "top",
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                    }).fire({
                        icon: 'success',
                        text: 'Đặt hàng thành công',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(async (result) => {
                        navigate(path.home);
                        localStorage.removeItem('carts');
                        setProductInCart([]);
                        // Cập nhật danh sách voucher hệ thống
                        await getSystemVoucher();
                        // Cập nhật danh sách voucher đã đổi
                        await getChangedVoucher();
                        // Cập nhật danh sách hạng voucher
                        await getVoucherRanks();
                    });
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    useEffect(() => {
        if (location?.state?.voucherId && location?.state?.voucherId !== ''){
            setVoucherId(location?.state?.voucherId);
        }

    }, [location?.state?.voucherId]);

    useEffect(() => {
        if (voucherId!== undefined && voucherId!==''){
            getDataVoucherDetail(voucherId);
        }
    }, [voucherId]);


    useEffect(() => {
        getAddressID(location?.state?.address_id ?? 0);
    }, [location?.state?.address_id]);


    useEffect(() => {
        setTotalPrice(calculateTotalPrice(productInCart));
    }, [productInCart]);


    useEffect(() => {
        if (totalPrice < voucherDetail?.total_money_min) {
            Toast({type: 'error', message: 'Tổng tiền không đủ để đổi voucher'})
            setVoucherId('');
            setVoucherDetail('')
            setMoneyReduced('')
        }
    }, [totalPrice]);
    useEffect(() => {
        let storedProducts = []
        storedProducts = JSON.parse(localStorage.getItem('carts'));
        console.log(storedProducts)
    }, []);

    const handleRemoveProduct = (id) => {
        try {
            let storedProducts = []
            storedProducts = JSON.parse(localStorage.getItem('carts'));
            const existingProductIndex = storedProducts.findIndex(item => item.product_id === id);

            if (existingProductIndex !== -1) {
                storedProducts.splice(existingProductIndex, 1);

                setProductInCart(storedProducts);
                localStorage.setItem('carts', JSON.stringify(storedProducts));
            } else {
                console.error('Product not found in the cart');
            }
        } catch (error) {
            console.error('Error handling product removal:', error);
        }
    };

    const handleChangeQuantity = (type, id) => {
        try {
            let storedProducts = [];
            storedProducts = JSON.parse(localStorage.getItem('carts'));
            const existingProductIndex = storedProducts.findIndex(item => item.product_id === id);

            if (existingProductIndex !== -1) {
                if (type === 'increase') {
                    storedProducts[existingProductIndex].quantity++;
                    Toast({type: 'success', message: 'Cập nhật thành công'})
                } else if (type === 'decrease') {
                    if (storedProducts[existingProductIndex].quantity > 1) {
                        storedProducts[existingProductIndex].quantity--;
                        Toast({type: 'success', message: 'Cập nhật thành công'})
                    } else {
                        Toast({type: 'error', message: 'Số lượng không được nhỏ hơn 1'})
                    }
                }
                setProductInCart(storedProducts);
                localStorage.setItem('carts', JSON.stringify(storedProducts));
            } else {
                console.error('Product not found in the cart');
            }
        } catch (error) {
            console.error('Error handling quantity change:', error);
        }
    };

    const getSystemVoucher = async () => {
        const res = await request_url({url: api_customers.systemVoucher(), method: 'get'});
        if (res?.status) {
            console.log(res);
            setSystemVouchers(res?.pending_vouchers);
        }
    }

    const getChangedVoucher = async () => {
        const res = await request_url({url: api_customers.changeVoucher(), method: 'get'});
        if (res?.status) {
            setChangedVoucher(res?.pending_vouchers);
        }
    }

// Lấy danh sách hạng voucher từ API và cập nhật state
    const getVoucherRanks = async () => {
        try {
            const res = await request_url({url: api_customers.getVoucherRank(), method: 'get'});
            if (res?.status) {
                setVocherRanks(res?.pending_vouchers);
            }
        } catch (e) {
            console.log(e);
        }
    }
    // Cập nhật nếu tổng tiền nhỏ hơn min_moneny thì thông báo


    return (
        <div className={'wrap-cart'}>
            <div className='wrapp-header-title text-center py-3 sticky top-0 z-50'>
                <div className="relative">
                    <div className="text-white text-xl font-bold " >Giỏ hàng  </div>
                    <div className="absolute top-1/2  -translate-y-1/2 px-3" >
                       <Link to={path.home} className={'p-3'}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                               <path d="M3.74804 6.97146L9 1.45436L7.61553 0L0 8L7.61553 16L9 14.5456L3.74804 9.02854L2.93744 8.00008L3.74804 6.97146Z" fill="#E8FDFF"/>
                           </svg>
                       </Link>
                    </div>
                </div>
            </div>
            <div className="cart-content px-3 pt-3">
                <div className="cart-address bg-[#ECECEC] px-3 py-3 rounded-lg">
                    <div className={'flex justify-between font-nunito'}>
                        <div className={'text-sm font-medium text-[#263238] '}>Địa chỉ</div>
                        <Link to={path.change_address} className="font-bold text-sm text-[#D701AA]">Chọn địa chỉ</Link>
                    </div>

                    <div className="info-address text-sm font-nunito text-[#828282] px-2 mt-3">
                        <div className="flex gap-3 mb-2">
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none">
                                    <g clipPath="url(#clip0_8_303)">
                                        <path
                                            d="M7.5 6C8.775 6 9.795 4.9875 9.795 3.75C9.795 2.5125 8.775 1.5 7.5 1.5C6.225 1.5 5.205 2.5125 5.205 3.75C5.205 4.9875 6.225 6 7.5 6ZM7.5 7.5C5.4 7.5 3.705 5.82 3.705 3.75C3.705 1.68 5.4 0 7.5 0C9.6 0 11.295 1.68 11.295 3.75C11.295 5.82 9.6 7.5 7.5 7.5ZM2.25 13.5H12.75V12.5025C12.75 11.19 11.0175 9.8325 7.5 9.8325C3.9825 9.8325 2.25 11.19 2.25 12.5025V13.5ZM7.5 8.3325C12.495 8.3325 14.25 10.83 14.25 12.5025V15H0.75V12.5025C0.75 10.83 2.505 8.3325 7.5 8.3325Z"
                                            fill="#828282"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_8_303">
                                            <rect width="15" height="15" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="">
                                {
                                    address?.first_name === undefined && address?.last_name === undefined
                                        ? 'Hãy thêm địa chỉ'
                                        : (address?.first_name + ' ' + address?.last_name)
                                }

                            </div>
                        </div>
                        <div className="flex gap-3 mb-2">
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"
                                     fill="none">
                                    <path
                                        d="M8.49996 9.91667C10.0625 9.91667 11.3333 8.64592 11.3333 7.08333C11.3333 5.52075 10.0625 4.25 8.49996 4.25C6.93738 4.25 5.66663 5.52075 5.66663 7.08333C5.66663 8.64592 6.93738 9.91667 8.49996 9.91667ZM8.49996 5.66667C9.28125 5.66667 9.91663 6.30204 9.91663 7.08333C9.91663 7.86462 9.28125 8.5 8.49996 8.5C7.71867 8.5 7.08329 7.86462 7.08329 7.08333C7.08329 6.30204 7.71867 5.66667 8.49996 5.66667Z"
                                        fill="#828282"/>
                                    <path
                                        d="M8.08915 15.4516C8.20903 15.5372 8.35267 15.5832 8.49998 15.5832C8.6473 15.5832 8.79093 15.5372 8.91081 15.4516C9.12615 15.2993 14.1872 11.645 14.1666 7.08335C14.1666 3.9589 11.6244 1.41669 8.49998 1.41669C5.37552 1.41669 2.83331 3.9589 2.83331 7.07981C2.81277 11.645 7.87381 15.2993 8.08915 15.4516ZM8.49998 2.83335C10.8439 2.83335 12.75 4.73948 12.75 7.0869C12.7649 10.2305 9.64181 13.0532 8.49998 13.979C7.35886 13.0525 4.23511 10.2291 4.24998 7.08335C4.24998 4.73948 6.15611 2.83335 8.49998 2.83335Z"
                                        fill="#828282"/>
                                </svg>
                            </div>
                            <div
                                className="">{address?.address}, {address?.province_name}, {address?.district_name}, {address?.ward_name}
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none">
                                    <path
                                        d="M12.1874 13.5938H12.1078C2.89682 13.0641 1.58901 5.29219 1.4062 2.92031C1.39147 2.7359 1.41327 2.55039 1.47036 2.37441C1.52745 2.19844 1.61869 2.03545 1.73888 1.89481C1.85906 1.75416 2.00582 1.6386 2.17073 1.55477C2.33565 1.47093 2.51549 1.42046 2.69995 1.40625H5.28276C5.47052 1.40607 5.65401 1.46227 5.80946 1.56758C5.96491 1.67288 6.08516 1.82244 6.15464 1.99688L6.86714 3.75C6.93574 3.92041 6.95276 4.10722 6.91609 4.28723C6.87943 4.46724 6.79068 4.6325 6.66089 4.7625L5.66245 5.77031C5.81841 6.6566 6.24285 7.47359 6.87834 8.11076C7.51383 8.74793 8.3297 9.17452 9.21557 9.33281L10.2328 8.325C10.3647 8.19664 10.5315 8.10996 10.7124 8.07578C10.8933 8.04161 11.0803 8.06144 11.2499 8.13281L13.0171 8.84063C13.1889 8.91229 13.3355 9.03348 13.4382 9.18875C13.5409 9.34403 13.595 9.52635 13.5937 9.7125V12.1875C13.5937 12.5605 13.4455 12.9181 13.1818 13.1819C12.9181 13.4456 12.5604 13.5938 12.1874 13.5938ZM2.81245 2.34375C2.68813 2.34375 2.5689 2.39314 2.48099 2.48104C2.39308 2.56895 2.3437 2.68818 2.3437 2.8125V2.85C2.55932 5.625 3.94214 12.1875 12.1593 12.6562C12.2209 12.6601 12.2826 12.6517 12.341 12.6316C12.3993 12.6115 12.4531 12.58 12.4993 12.5391C12.5454 12.4982 12.583 12.4485 12.61 12.393C12.6369 12.3375 12.6526 12.2772 12.6562 12.2156V9.7125L10.889 9.00469L9.5437 10.3406L9.3187 10.3125C5.24057 9.80156 4.68745 5.72344 4.68745 5.68125L4.65932 5.45625L5.99057 4.11094L5.28745 2.34375H2.81245Z"
                                        fill="#828282"/>
                                </svg>
                            </div>
                            <div className="">{address?.phone !== undefined ? address?.phone : 'Hãy thêm địa chỉ'}</div>
                        </div>
                    </div>

                </div>

                <div className="list-product-cart mt-5">

                    {
                        productInCart?.map((product, index) => (
                            <div key={index} className="item-pro-cart flex gap-4 font-nunito mb-5">
                                <div className="">
                                    <img src={product?.product_image} alt={''}
                                         className={'w-[89px] h-[93px] object-cover rounded-lg border-2 border-[#CF01A4]'}/>
                                </div>
                                <div className="flex-1 ">
                                    <div className={'flex gap-4'}>
                                        <div className="text-[15px] text-[#333333] ">{product?.product_name}
                                        </div>

                                        <div className="ms-auto">
                                            <div className="bg-[#D801AB] text-[15px] text-white  px-3  rounded-lg"
                                                 onClick={() => handleRemoveProduct(product?.product_id)}>xóa
                                            </div>
                                        </div>


                                    </div>
                                    <div
                                        className="text-[#D60013] text-xs font-bold mt-1">{formatCurrency(product?.product_price)}đ
                                    </div>
                                    <div className={'flex items-center'}>
                                        {/*<div className="">*/}
                                        {/*    <del className={'text-[11px] text-[#828282]'}>850.000đ</del>*/}
                                        {/*</div>*/}
                                        <div className="ms-auto">
                                            <div className="flex  rounded-lg h-[26px]  ">
                                                <span
                                                    className={'bg-[#E97BF2] px-3 rounded-l-lg text-[24px]   border-2 border-[#BE37A0] flex items-center border-r-0'}
                                                    onClick={() => handleChangeQuantity('decrease', product?.product_id)}>-</span>
                                                <span
                                                    className={'w-[50px] text-center text-[17px] flex items-center justify-center  border-2 border-[#FB01C4] '}>{product?.quantity}</span>
                                                <span
                                                    className={'bg-[#E97BF2] px-3 rounded-r-lg text-[24px] border-2 border-[#BE37A0] flex items-center border-l-0'}
                                                    onClick={() => handleChangeQuantity('increase', product?.product_id)}>+</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        ))
                    }

                </div>


                <div className="cart-pay font-nunito border-b-2 border-[#DD01AE] pb-5">
                    <div className="text-[#D701AA] text-[15px] font-bold">Thanh toán</div>
                    <div className="flex justify-between mt-3">
                        <span className={'text-[15px] font-light'}>Đơn giá tổng</span>
                        <span
                            className={'text-sm font-bold text-[#D701AA]'}>{formatCurrency(calculateTotalPrice(productInCart))}đ</span>
                    </div>
                    <div className="flex justify-between mt-3">
                        <span className={'text-[15px] font-light'}>Voucher</span>
                        <Link to={path.voucher}
                              className={'text-[15px] px-2 rounded bg-[#D701AA] text-white'}>Chọn</Link>
                    </div>
                    <div className="flex justify-between mt-3 items-center">
                        <span className={'text-[15px] font-light'}>Tiền được giảm</span>
                        <del className={'text-xs text-[#828282]'}>{formatCurrency(moneyReduced)}đ</del>
                    </div>
                </div>
                <div className="px-3">
                    <div className="flex items-center text-[#D701AA] font-bold justify-between mt-5">
                        <div className="text-[15px]">Tổng thanh toán</div>
                        <div className="text-sm ">{formatCurrency(totalPrice - moneyReduced)}đ</div>
                    </div>
                </div>
            </div>
            <div className=" pb-[120px]"></div>
            <div className="btn-payment fixed bottom-0 left-0 w-full h-[120px] font-nunito">
                <div className="d-flex h-full grid grid-cols-2 items-center justify-between px-3 py-3">
                    <div className="">
                        <div className='font-bold text-sm text-[#D701AA] '>Tiền
                            hàng: {formatCurrency(totalPrice - moneyReduced)}đ
                        </div>
                        <div
                            className={'text-[15px] font-semibold text-white text-center bg-[#D701AA] rounded-lg py-2 mt-2'}
                            onClick={handleOrder}>Đặt
                            hàng
                        </div>
                    </div>
                    <div className="text-right">
                        <div className="text-[15px]">{address?.first_name} {address?.last_name}</div>
                        <div
                            className={'text-[#828282] text-sm'}>{address?.address}, {address?.province_name}, {address?.district_name}, {address?.ward_name}
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )

}
export default Cart;