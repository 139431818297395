import {path} from "../../routes/data";
import {useEffect, useState} from "react";
import {api_customers, request_url} from "../../api";
import {Link} from "react-router-dom";

const CreateAddress = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [provinceId, setProvinceId] = useState('')
    const [districtId, setDistrictId] = useState('')
    const [wardId, setWardId] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [province, setProvince] = useState(null)
    const [district, setDistrict] = useState(null)
    const [ward, setWard] = useState(null)
    const getProvince = async () => {
        const res = await request_url({url: api_customers.getProvince()})
        setProvince(res?.data);
    }
    const getDistrict = async (provinceId) => {
        const res = await request_url({url: api_customers.getDistrict(provinceId)})
        setDistrict(res?.data);
    }
    const getWard = async (districtId) => {
        const res = await request_url({url: api_customers.getWard(districtId)})
        setWard(res?.data);
    }
    useEffect(() => {
        getProvince();
    }, []);

    useEffect(() => {
        if (provinceId) {
            getDistrict(provinceId);
            getWard(null);
        }
    }, [provinceId]);

    useEffect(() => {
        if (districtId) {
            getWard(districtId);
        }
    }, [districtId]);

    const handleSubmit =async (e) => {
        e.preventDefault();

        const formData=new FormData();
        formData.append('first_name',firstName);
        formData.append('last_name',lastName);
        formData.append('province_id',provinceId);
        formData.append('district_id',districtId);
        formData.append('ward_id',wardId);
        formData.append('address',address);
        formData.append('phone',phone);
        const  res=await  request_url({url:api_customers.createAddress(),method:'POST',data:formData})
        console.log(res)
        if (res?.status){
            window.location.href=path.change_address;
        }


    }



    return (
        <div>
            <div className='wrapp-header-title text-center py-3 sticky top-0 z-50'>
                <div className="relative">
                    <div className="text-white text-xl font-bold " >Thêm địa chỉ mới</div>
                    <div className="absolute top-1/2  -translate-y-1/2 px-3" >
                        <Link to={path.change_address} className={'p-3'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                                <path d="M3.74804 6.97146L9 1.45436L7.61553 0L0 8L7.61553 16L9 14.5456L3.74804 9.02854L2.93744 8.00008L3.74804 6.97146Z" fill="#E8FDFF"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={'mt-5 px-3'}>


                <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
                    <div className="relative z-0 w-full mb-5 group">
                        <input type="text" id="floating_email"
                               className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               placeholder=" " onChange={(e) => setFirstName(e.target.value)} required/>
                        <label htmlFor="floating_email"
                               className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Họ
                        </label>
                    </div>

                    <div className="relative z-0 w-full mb-5 group">
                        <input type="text" id="floating_email"
                               className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               placeholder=" " onChange={(e) => setLastName(e.target.value)} required/>
                        <label htmlFor="floating_email"
                               className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Tên
                        </label>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                        <input type="number" id="floating_email"
                               className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               placeholder=" " onChange={(e) => setPhone(e.target.value)} required/>
                        <label htmlFor="floating_email"
                               className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Số điện thoại
                        </label>
                    </div>
                    <div>
                        <label htmlFor="default"
                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tỉnh/Thành
                            phố</label>
                        <select id="default"
                                className="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setProvinceId(e.target.value)}>
                            <option selected>--Chọn tỉnh thành phố--</option>
                            {
                                province?.map((item, index) => (
                                    <option value={item?.ProvinceID} key={index}>{item?.ProvinceName}</option>
                                ))
                            }

                        </select>
                    </div>

                    <div>
                        <label htmlFor="default"
                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Quận/huyện</label>
                        <select id="default"
                                className="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setDistrictId(e.target.value)}>
                            <option selected>--Chọn quận huyện--</option>
                            {
                                district?.map((item, index) => (
                                    <option value={item?.DistrictID} key={index}>{item?.DistrictName}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div>
                        <label htmlFor="default"
                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phường/Xã</label>
                        <select id="default"
                                className="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setWardId(e.target.value)}>
                            <option selected>--Chọn phường xã--</option>
                            {
                                ward?.map((item, index) => (
                                    <option value={item?.id} key={index}>{item?.WardName}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="relative z-0 w-full mb-5 group">
                        <input type="text" id="floating_email"
                               className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                               placeholder=" " onChange={(e) => setAddress(e.target.value)} required/>
                        <label htmlFor="floating_email"
                               className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Địa chỉ
                        </label>
                    </div>

                    <button type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit
                    </button>
                </form>

            </div>


        </div>
    )

}
export default CreateAddress;