import {useNavigate} from "react-router-dom";
import {Toast} from "../../component/utili";

const ItemGift = ({title, content, point, id, image, quantity}) => {

    const navigate = useNavigate();
    const navExchangeGift = async () => {

        if (quantity === 0) {
            Toast({type: 'warning', message: 'Quà tặng đã hết số lượng'});
            return;
        }
        navigate('/exchange-gift', {
            state: {
                id: id,
                title: title,
                content: content,
                point: point,
                image: image,
                quantity: quantity
            }
        })
    }

    return (
        <div className={`wrap-item-voucher border-2 border-[#C7C9D] rounded-lg  mb-3 h-[120px] `}>
            <div className="w-full flex items-center h-full ">
                <div className="p-3"><img src={image} alt="" className={'w-[100px] h-[100px] object-cover'}/></div>
                <div className="border-dashed border-2 border-[#C7C9D9] h-full "></div>
                <div className="p-3 font-mont flex-grow h-full">
                    <div className="text-base font-semibold  text-[#28293D] flex">
                        <span>{title}</span>
                        <span className={'ms-auto'}><img src={image.icon_i} alt=""/></span>
                    </div>
                    <div className={'text-sm text-[#8F90A6] pe-10'}>{content}</div>
                    <div className="flex items-center mt-1">
                        <span className={'text-xs text-[#8F90A6]'}>Số lượng còn:<span
                            className={'text-[#00408B] font-bold mx-1'}>{quantity}</span></span>
                    </div>
                    <div className="flex items-center">
                        <span className={'text-xs text-[#8F90A6]'}>Số điểm đổi:<span
                            className={'text-[#00408B] font-bold mx-1'}>{point}</span>điểm</span>
                        {
                            quantity !== 0 ? (
                                <span
                                    className={'ms-auto  font-nunito rounded bg-[#00408B] text-white text-[15px] px-2 py-1'}
                                    onClick={navExchangeGift}
                                >Đổi ngay</span>
                            ): (
                                <span
                                    className={'ms-auto  font-nunito rounded bg-red-700 text-white text-[15px] px-2 py-1'}

                                >Hết quà</span>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>

    )
}
export default ItemGift;