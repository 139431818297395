import {url} from "../../api";
import {Link} from "react-router-dom";
import {path} from "../../routes/data";

const ItemNotification = ({title,content,date,id,image}) => {
  return(
      <Link to={`${path.notification_detail}/${id}`} className="p-2 rounded-lg border-[1px] border-[#EE01BB] flex gap-3 relative ">
          <div className="w-[35%] relative flex-shrink-0">
              <img src={url +image} className={'w-full h-full top-0 left-0 absolute rounded-lg'}
                   alt={''}/>
              <div className={'pt-[100%]'}></div>
          </div>
          <div className="flex-grow">
             <div className="flex-col h-full w-full ">
                 <div className="text-sm font-semibold font-nunito text-[#09121F] line-2">
                     {title}
                 </div>
                 <div className="line-3 text-sm font-light font-nunito text-[#09121F]">
                     <div dangerouslySetInnerHTML={{ __html:content }} />
                 </div>
                 <div className="justify-end gap-2 flex mt-auto">
                           <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"
                                      fill="none">
                                                            <path
                                                                d="M11.859 3.37725L8.5605 8.5605L3.37725 11.859C4.30269 12.7366 5.48448 13.2953 6.75 13.4535V12H8.25V13.4535C9.31803 13.3205 10.3302 12.9012 11.1795 12.24L10.152 11.2125L11.2125 10.152L12.24 11.1795C12.9012 10.3302 13.3205 9.31803 13.4535 8.25H12V6.75H13.4535C13.2953 5.48448 12.7366 4.30269 11.859 3.37725ZM11.6227 3.141C10.6973 2.26343 9.51552 1.70473 8.25 1.5465V3H6.75V1.5465C5.68197 1.67954 4.66976 2.09883 3.8205 2.76L4.848 3.7875L3.7875 4.848L2.76 3.8205C2.09883 4.66976 1.67954 5.68197 1.5465 6.75H3V8.25H1.5465C1.70473 9.51552 2.26343 10.6973 3.141 11.6227L11.1795 11.1795L11.6227 3.141ZM7.5 15C3.35775 15 0 11.6423 0 7.5C0 3.35775 3.35775 0 7.5 0C11.6423 0 15 3.35775 15 7.5C15 11.6423 11.6423 15 7.5 15Z"
                                                                fill="#A3A6AB"/>
<                               /svg></span>
                     <span className={'text-[#A3A6AB] text-[13px] font-medium font-nunito'}>{date}</span>
                 </div>
             </div>
          </div>
          <div className="absolute size-[12px] bg-[#FD01C5] rounded-full top-2 right-2"></div>
      </Link>
  )
}
export  default  ItemNotification;