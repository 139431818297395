import HeaderTitle from "../../component/header_title";
import ItemNotification from "./itemNotification";
import {useEffect, useState} from "react";
import {api_customers, request_url} from "../../api";

function Notification() {
    const [notifications, setNotifications] = useState([])
    const Notifications=async ()=> {
        const res = await request_url({
            url: api_customers.getNotification(),
            method: 'GET',
        })
        if (res?.status) {
            setNotifications(res?.notifications)
        }
    }

    useEffect(() => {
        Notifications()
    }, []);
    return (
        <div className="">
            <HeaderTitle title="Thông báo"/>
            <div className="p-5">
                {
                    notifications.map((item, i) =>(
                        <div key={i} className="mb-3">
                            <ItemNotification title={item.title} content={item.content} date={item.created_at} id={item.id} image={item?.image} />
                        </div>
                    ))
                }

            </div>

        </div>
    )
}

export default Notification;