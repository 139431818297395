import React from 'react';
import {useNavigate} from 'react-router-dom';
import Product_Item from "../../component/product_item";
import {path} from "../../routes/data";

const ProductByCategory = ({category}) => {
    const navigate = useNavigate();


    const handBuy = (event) => {
        navigate(path.buy, {state: {id: category?.category_id}});
    };

    return (
        <div className="mt-5 ps-5">
            <div className="list-poduct-category">
                <div className="flex justify-between">
                    <div className="title-cate text-base font-semibold">
                        {category?.category_name}
                    </div>
                    <div onClick={handBuy} className="text-sm font-light pe-3">Tất
                        cả
                    </div>
                </div>
                <div className="list-product-main flex gap-4 py-2 mt-3">
                    {category?.data?.map((product, index) => (
                        product?.image && product?.image.src !== '' && product?.image?.src !== null && (
                            <div className="h-item-product item bg" key={index}>
                                <Product_Item key={index} product={product} />
                            </div>
                        )
                    ))}
                </div>

            </div>
        </div>
    );
};

export default ProductByCategory;
