import HeaderTitle from "../../component/header_title";
import {image} from "../../assets/image";
import ItemSystemVoucher from "../voucher/ItemSystemVoucher";
import {useEffect} from "react";
import {api_customers, request_url} from "../../api";
import {useStateContext} from "../../contexts/StateContext";

const ClassMember = () => {

    const {vocherRanks,setVocherRanks}=useStateContext();

    const getDataVoucherRanks = async ()=> {
        try {
            const res = await request_url({url:api_customers.getVoucherRank(),method:'get'});
            if(res?.status){
                setVocherRanks(res?.pending_vouchers);
            }
        }catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getDataVoucherRanks();
    }, []);
    return (
        <div className={'wrapp-class-member'}>
            <HeaderTitle title={'Hạng thành viên'}/>
            <div className="mt-3 px-5">
                <img src={image.class_member} className={'rounded-lg'} alt=""/>
            </div>
            <div className="mt-3 px-5">
                <div className="text-base font-semibold font-nunito">Voucher dành riêng cho hạng</div>
                <div className="mt-3">
                    {
                        vocherRanks?.map((item,index)=>{
                            return <ItemSystemVoucher  key={index} title={item.title}
                                                           content={item.content} point={item.point} id={item?.id} image={item?.image}/>
                        })
                    }

                </div>
                <div className="text-base font-semibold font-nunito my-5">Đặc quyền dành riêng cho thẻ VIP</div>
                <div className="text-sm font-sans text-[#06070C]">
                    <p className={'mb-3'}>Đối với những khách hàng thuộc hạng thành viên VIP sẽ được ưu
                        tiên cho tất cả các sự kiện trọng điểm trong năm của LÊ UYÊN</p>
                        <ul className={'mb-3'}>
                            <li>Được ưu tiên các sự kiện Offline đăng ký</li>
                            <li>Được hưởng các ưu đãi riêng của voucher</li>
                            <li>Hạng Được nhận các chương trình riêng theo hạng thẻ</li>
                            <li>Nhận quà tặng đặc biệt theo các sự kiện Mini Game</li>
                        </ul>
                    <p className={'mb-3'}>
                        Được ưu tiên các sự kiện Offline đăng ký
                        Được hưởng các ưu đãi riêng của voucher Hạng
                        Được nhận các chương trình riêng theo hạng thẻ
                        Nhận quà tặng đặc biệt theo các sự kiện Mini Game
                    </p>
                </div>
            </div>


        </div>
    )

}
export default ClassMember;