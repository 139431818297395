import {path} from "../../routes/data";
import {useEffect, useState} from "react";
import  {api_customers, request_url} from "../../api";
import {Link, useNavigate} from "react-router-dom";

const Address = () => {
    const [adderesses, setAdderesses] = useState();
    const navigate=useNavigate();
    const getDataAddress = async () => {
        const res=await request_url({url:api_customers.getAddress()})
       setAdderesses(res?.data)
    }
    useEffect(() => {
        getDataAddress().then(r => (
            console.log(r)
        ))
    }, []);

    const backCart=(id)=> {
        navigate(path.cart,{state:{address_id:id}})
    }

    return (
        <div>
            <div className='wrapp-header-title text-center py-3 sticky top-0 z-50'>
                <div className="relative">
                    <div className="text-white text-xl font-bold " >Chọn địa chỉ</div>
                    <div className="absolute top-1/2  -translate-y-1/2 px-3" >
                        <Link to={path.cart} className={'p-3'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                                <path d="M3.74804 6.97146L9 1.45436L7.61553 0L0 8L7.61553 16L9 14.5456L3.74804 9.02854L2.93744 8.00008L3.74804 6.97146Z" fill="#E8FDFF"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={'mt-3 px-3'}>
                <div className={'mb-3 flex justify-end'}>
                    <Link to={path.create_address} className='px-2 bg-emerald-600 text-white rounded-lg py-1 '>Thêm  mới</Link>
                </div>
                {
                    adderesses?.map((item, index) => (
                        <div key={index} className="cart-address bg-[#ECECEC] px-3 py-3 rounded-lg mb-3">
                            <div className={'flex justify-between font-nunito'}>
                                <div className={'text-sm font-medium text-[#263238] '}>Địa chỉ</div>
                                <div className="font-bold text-sm text-[#D701AA]" onClick={()=>backCart(item?.id)}>Chọn</div>
                            </div>

                            <div className="info-address text-sm font-nunito text-[#828282] px-2 mt-3">
                                <div className="flex gap-3 mb-2">
                                    <div className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"
                                             fill="none">
                                            <g clipPath="url(#clip0_8_303)">
                                                <path
                                                    d="M7.5 6C8.775 6 9.795 4.9875 9.795 3.75C9.795 2.5125 8.775 1.5 7.5 1.5C6.225 1.5 5.205 2.5125 5.205 3.75C5.205 4.9875 6.225 6 7.5 6ZM7.5 7.5C5.4 7.5 3.705 5.82 3.705 3.75C3.705 1.68 5.4 0 7.5 0C9.6 0 11.295 1.68 11.295 3.75C11.295 5.82 9.6 7.5 7.5 7.5ZM2.25 13.5H12.75V12.5025C12.75 11.19 11.0175 9.8325 7.5 9.8325C3.9825 9.8325 2.25 11.19 2.25 12.5025V13.5ZM7.5 8.3325C12.495 8.3325 14.25 10.83 14.25 12.5025V15H0.75V12.5025C0.75 10.83 2.505 8.3325 7.5 8.3325Z"
                                                    fill="#828282"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_8_303">
                                                    <rect width="15" height="15" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className="">{item?.first_name} {item?.last_name}</div>
                                </div>
                                <div className="flex gap-3 mb-2">
                                    <div className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"
                                             fill="none">
                                            <path
                                                d="M8.49996 9.91667C10.0625 9.91667 11.3333 8.64592 11.3333 7.08333C11.3333 5.52075 10.0625 4.25 8.49996 4.25C6.93738 4.25 5.66663 5.52075 5.66663 7.08333C5.66663 8.64592 6.93738 9.91667 8.49996 9.91667ZM8.49996 5.66667C9.28125 5.66667 9.91663 6.30204 9.91663 7.08333C9.91663 7.86462 9.28125 8.5 8.49996 8.5C7.71867 8.5 7.08329 7.86462 7.08329 7.08333C7.08329 6.30204 7.71867 5.66667 8.49996 5.66667Z"
                                                fill="#828282"/>
                                            <path
                                                d="M8.08915 15.4516C8.20903 15.5372 8.35267 15.5832 8.49998 15.5832C8.6473 15.5832 8.79093 15.5372 8.91081 15.4516C9.12615 15.2993 14.1872 11.645 14.1666 7.08335C14.1666 3.9589 11.6244 1.41669 8.49998 1.41669C5.37552 1.41669 2.83331 3.9589 2.83331 7.07981C2.81277 11.645 7.87381 15.2993 8.08915 15.4516ZM8.49998 2.83335C10.8439 2.83335 12.75 4.73948 12.75 7.0869C12.7649 10.2305 9.64181 13.0532 8.49998 13.979C7.35886 13.0525 4.23511 10.2291 4.24998 7.08335C4.24998 4.73948 6.15611 2.83335 8.49998 2.83335Z"
                                                fill="#828282"/>
                                        </svg>
                                    </div>
                                    <div className="">{item?.address}, {item?.province_name}, {item?.district_name}, {item?.ward_name},</div>
                                </div>
                                <div className="flex gap-3">
                                    <div className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"
                                             fill="none">
                                            <path
                                                d="M12.1874 13.5938H12.1078C2.89682 13.0641 1.58901 5.29219 1.4062 2.92031C1.39147 2.7359 1.41327 2.55039 1.47036 2.37441C1.52745 2.19844 1.61869 2.03545 1.73888 1.89481C1.85906 1.75416 2.00582 1.6386 2.17073 1.55477C2.33565 1.47093 2.51549 1.42046 2.69995 1.40625H5.28276C5.47052 1.40607 5.65401 1.46227 5.80946 1.56758C5.96491 1.67288 6.08516 1.82244 6.15464 1.99688L6.86714 3.75C6.93574 3.92041 6.95276 4.10722 6.91609 4.28723C6.87943 4.46724 6.79068 4.6325 6.66089 4.7625L5.66245 5.77031C5.81841 6.6566 6.24285 7.47359 6.87834 8.11076C7.51383 8.74793 8.3297 9.17452 9.21557 9.33281L10.2328 8.325C10.3647 8.19664 10.5315 8.10996 10.7124 8.07578C10.8933 8.04161 11.0803 8.06144 11.2499 8.13281L13.0171 8.84063C13.1889 8.91229 13.3355 9.03348 13.4382 9.18875C13.5409 9.34403 13.595 9.52635 13.5937 9.7125V12.1875C13.5937 12.5605 13.4455 12.9181 13.1818 13.1819C12.9181 13.4456 12.5604 13.5938 12.1874 13.5938ZM2.81245 2.34375C2.68813 2.34375 2.5689 2.39314 2.48099 2.48104C2.39308 2.56895 2.3437 2.68818 2.3437 2.8125V2.85C2.55932 5.625 3.94214 12.1875 12.1593 12.6562C12.2209 12.6601 12.2826 12.6517 12.341 12.6316C12.3993 12.6115 12.4531 12.58 12.4993 12.5391C12.5454 12.4982 12.583 12.4485 12.61 12.393C12.6369 12.3375 12.6526 12.2772 12.6562 12.2156V9.7125L10.889 9.00469L9.5437 10.3406L9.3187 10.3125C5.24057 9.80156 4.68745 5.72344 4.68745 5.68125L4.65932 5.45625L5.99057 4.11094L5.28745 2.34375H2.81245Z"
                                                fill="#828282"/>
                                        </svg>
                                    </div>
                                    <div className="">{item?.phone}</div>
                                </div>
                            </div>

                        </div>
                        ))
                }

            </div>


        </div>
    )

}
export default Address;