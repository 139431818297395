import React, {createContext, useContext, useEffect, useState} from 'react';
import {api_customers, request_res, request_url} from "../api";
import {useNavigate} from "react-router-dom";
import {path} from "../routes/data";

const StateContext = createContext();

export const useStateContext = () => useContext(StateContext);

export const StateProvider = ({children}) => {
    const [state, setState] = useState();
    const [phone, setPhone] = useState('');
    const [user, setUser] = useState();
    const [productInCart, setProductInCart] = useState([]);
    const [numberPage, setNumberpage] = useState(0);
    const [systemVouchers, setSystemVouchers] = useState([])
    const [changedVoucher, setChangedVoucher] = useState([])
    const [vocherRanks, setVocherRanks] = useState([]);
    const [point, setPoint] = useState('')
    const navigate = useNavigate();


    useEffect(() => {
        const phone = localStorage.getItem('phone');
        console.log(phone)
        if (!phone) {
            navigate(path.login);
        }
    }, []);


    useEffect(() => {
        const storedPhone = localStorage.getItem('phone');

        if (storedPhone) {
            setPhone(storedPhone);
        }

    }, []);


    useEffect(() => {
        const products = localStorage.getItem('carts');
        if (products) {
            const parsedProducts = JSON.parse(products);
            setProductInCart(parsedProducts);
        }
    }, [])
    const login = async () => {
        try {
            const response = await request_res({ url: api_customers.login(), method: 'POST', data: { phone } });

            if (response?.status === true) {
                localStorage.setItem('token_customer', response?.token);
                localStorage.setItem('phone', phone);
                localStorage.setItem('user', response?.user);
                setUser(response?.user);
                setPoint(response?.user?.total_earned_points - response?.user?.total_spent_points);
            } else {
                console.error('Login failed:', response);
            }
        } catch (error) {
            console.error('Error during login:', error);
           
        }
    };

    useEffect(() => {
        if (phone) {

            login();
            
        }
    }, [phone]);




    return (
        <StateContext.Provider value={{
            state,
            setState,
            numberPage,
            setNumberpage,
            user,
            setUser,
            productInCart,
            setProductInCart,
            systemVouchers,
            setSystemVouchers,
            changedVoucher,
            setChangedVoucher,
            phone,
            setPhone,
            vocherRanks,
            setVocherRanks,point,setPoint
        }}>
            {children}
        </StateContext.Provider>
    );
};

export default StateContext;
