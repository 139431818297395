import {Link} from "react-router-dom";
import {url} from "../../api";
import {formatCurrency} from "../../component/utili";

const ItemByHistory = ({image,name,price,id,quantity}) => {
  return(
      <div  className="item-pro-cart flex gap-4 font-nunito mb-5">
          <div className="">
              <img src={image} alt={''}
                   className={'w-[89px] h-[93px] object-cover rounded-lg border-2 border-[#CF01A4]'}/>
          </div>
          <div className="flex-1 ">
              <div className={'flex gap-4'}>
                  <div className="text-[15px] text-[#333333] ">
                      {name}
                  </div>


              </div>
              <div className="text-[#D60013] text-xs font-bold mt-1">{formatCurrency(price)}đ</div>
              <div className={'flex items-center'}>
                  {/*<div className="">*/}
                  {/*    <del className={'text-[11px] text-[#828282]'}>850.000đ</del>*/}
                  {/*</div>*/}
                  <div className="ms-auto text-[13px] font-nunito">
                     Số lượng: {quantity}
                  </div>

              </div>
          </div>

      </div>
  )
}
export default ItemByHistory;