import HeaderTitle from "../../component/header_title";
import {Link} from "react-router-dom";
import ItemVoucher from "./ItemVoucher";
import ItemSystemVoucher from "./ItemSystemVoucher";
import {path} from "../../routes/data";
import {useEffect, useState} from "react";
import {api_customers, request_url} from "../../api";
import {useStateContext} from "../../contexts/StateContext";

const Voucher = () => {

   const {systemVouchers,setSystemVouchers,changedVoucher,setChangedVoucher,point}=useStateContext();
    const getSystemVoucher = async () => {
        const res = await request_url({url: api_customers.systemVoucher(), method: 'get'})
        if (res?.status) {
            setSystemVouchers(res?.pending_vouchers)

        }
    }
    const getChangedVoucher = async () => {
        const res = await request_url({url: api_customers.changeVoucher(), method: 'get'})
        if (res?.status) {
            setChangedVoucher(res?.pending_vouchers)
        }
    };
    useEffect(() => {
        getSystemVoucher();
        getChangedVoucher()
    }, []);
    return (
        <div className={'wrap-voucher'}>
            <HeaderTitle title={'Voucher'}/>
            <div className="voucher-content mt-3 px-5">
                <div className="banner-voucher text-white p-3 font-nunito">
                    <div className="flex">
                        <div className="w-[70%]">
                            <div
                                className="inline-block text-nowrap px-3 py-1 bg-[#9A0079]/50 rounded text-[11px] font-bold">
                                30 tháng 4,2024
                            </div>
                            <div className="text-base font-extrabold">
                                Mở khoá những voucher
                                hấp dẫn cùng Lê Uyên
                            </div>
                        </div>
                        <div className="w-[40%] ps-3">
                            <div className="text-sm font-bold text-[#E8FDFF]">Bạn đang có</div>
                            <div className="">
                                <span className={'text-[40px] text-[#FFE600] font-bold'}>{point}</span>
                                <span className={'text-sm font-bold ms-2'}>điểm</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="list-vouchers mt-5">
                    <div className="mb-3">
                        <div className="flex items-center">
                            <div className="text-baseb font-semibold">Voucher đã đổi</div>
                            <Link to={path.myVoucher} className={'ms-auto text-sm font-light'}>Tất cả</Link>

                        </div>
                        <div className="mt-3">
                            {
                                changedVoucher?.map((item,idx)=>(
                                    <ItemVoucher key={idx} title={item?.title} content={item?.content} id={item?.id} image={item?.image} />
                                ))
                            }
                        </div>


                    </div>
                </div>
                <div className="list-vouchers mt-5">
                    <div className="mb-3">
                        <div className="flex items-center">
                            <div className="text-baseb font-semibold">Voucher hệ thống</div>
                            <Link to={path.systemVoucher} className={'ms-auto text-sm font-light'}>Tất cả</Link>

                        </div>
                        <div className="mt-3">
                            {
                                systemVouchers?.map((item,idx)=>(
                                    <ItemSystemVoucher key={idx}  title={item?.title} content={item?.content} point={item?.point} id={item?.id} image={item?.image} />

                                ))
                            }
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )

}
export default Voucher;