import {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import  {api_customers, request_url, url} from "../../api";
import HeaderTitle from "../../component/header_title";



const PostBabyDetail = () => {
    const { id } = useParams();
    const [post, setPost] = useState()
    useEffect(() => {
        const fetchNotificationDetail = async () => {
            try {
                const res = await request_url({
                    url: api_customers.getNotificationDetail(id),
                    method: 'GET',
                    data: null
                });
                if (res.status){
                    setPost(res?.notification)
                }
            } catch (error) {
                console.error('Error fetching notification detail:', error);
            }
        };

        if (id) {
            fetchNotificationDetail();
        }
    }, [id]); // Re-run the effect when id changes

    return(
        <div>
            <HeaderTitle title="Chi tiết thông báo"/>
            <div className="p-5">
                <div className="text-sm font-sans font-medium text-[#06070C]">{post?.title}</div>
                <div className="w-full relative">
                    <img src={url + post?.image} alt="" className='absolute w-full h-full object-cover rounded-lg top-0 left-0'/>
                    <div className="pt pt-[50%]"></div>
                </div>
                <div className="mt-3 text-xs font-sans">
                    <div dangerouslySetInnerHTML={{ __html:post?.content }} />

                </div>

            </div>
        </div>
    )
}

export default  PostBabyDetail;