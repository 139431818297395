import avatar from './avatar.png'
import icon_point from './pont.svg'
import icon_cart from './icon_cart.svg'
import banner from './banner.png'
import icon_buy_history from './icon_buy_history.png'
import icon_mua_sam from './icon_mua_sam.png'
import icon_phan_hoi from './icon_phan_hoi.png'
import icon_quyen_loi from './icon_quyen_loi.png'
import icon_tich_diem from './icon_tich_diem.png'
import icon_tim_san_pham from './icon_tim_san_pham.png'
import icon_tu_van from './icon_tu_van.png'
import icon_voucher from './ion_voucher.png'
import icon_search from './icon_search.png'
import product_item from './product_item.png'
import bg_bottom from './bg_bottom.png'
import icon_qr from './icon_qr.png'
import baby from './baby.png'
import icon_milk from './icon_milk.png'
import icon_freship from './icon_freship.png'
import icon_i from './icon_i.png';
import class_member from './class_member.png'
import account_b from './account-b.png'
import account from './Profile.png'
import thong_bao from './thong_bao.png'
import bag from './Bag.png'
import home from    './home.png'
import  avatar_default from './avatar.jpg'
import home_active from './home_active.png'
import  buy_active from './buy_active.png'
import notification_active from './notification_active.png'
import  profile_active from './profile_active.png'

const image = {
    avatar,
    icon_point,
    icon_cart,
    banner
    ,
    avatar_default,
    icon_buy_history,
    icon_voucher,
    icon_mua_sam,
    icon_phan_hoi,
    icon_quyen_loi,
    icon_tich_diem,
    icon_tim_san_pham,
    icon_tu_van,
    icon_search,
    product_item,bg_bottom
    ,icon_qr,baby,icon_milk,icon_freship,icon_i,class_member,account_b,
    account,thong_bao,bag,home,
    home_active,buy_active,notification_active,profile_active

}

export {image}