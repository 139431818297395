import HeaderTitle from "../../component/header_title";
import ItemByHistory from "./itemByHistory";
import {useEffect, useState} from "react";
import {api_customers, request_url} from "../../api";

const HistoryBy = () => {

    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState(1);

    const data = [
        {
            id: 1,
            title: 'Chờ xác nhận',

        },
        {
            id: 2,
            title: 'Đã xa nhận',

        },
        {
            id: 3,
            title: 'Đang giao',

        }, {
            id: 4,
            title: 'Đã nhận',

        }, {
            id: 5,
            title: 'Đã hủy',

        },

        ]

    const getOrder = async () => {
        const data= new FormData();
        data.append('status', status);
        const res = await request_url({url: api_customers.getOrders(), method: 'post',data:data});
        setOrders(res);
        if(res?.status){
            setOrders(res?.orders)
        }
    }

    useEffect(() => {
        if (status){
            getOrder();
        }
    }, [status]);
    return (
        <div className="">
            <HeaderTitle title={"Lịch sử mua hàng"}/>
            <div className="px-5">
                <div className="flex justify-between mt-5">
                    {
                        data?.map((item, index) => (
                            <div className="" key={index} onClick={()=>setStatus(item?.id)}>
                                <div className="text-xs font-sans ">{item?.title}</div>
                                {
                                    item?.id===status ? (
                                        <div className="h-[5px] w-full bg-[#D9D9D9] mt-1"></div>
                                    ):''
                                }
                            </div>
                        ))
                    }


                </div>

                <div className="mt-7">
                    {
                        orders?.map((order, index) => (
                            <div className="order mb-5 pb-5 border-b-2 border-[#D9D9D9]" key={index}>
                                <div className="flex gap-5 items-center mb-5">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18"
                                       fill="none">
  <path d="M8.12151 14.6044H1.0332" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M11.2832 3.7005H18.3715" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path fillRule="evenodd" clipRule="evenodd"
        d="M6.31707 3.63954C6.31707 2.18192 5.12664 1 3.65854 1C2.19043 1 1 2.18192 1 3.63954C1 5.09715 2.19043 6.27907 3.65854 6.27907C5.12664 6.27907 6.31707 5.09715 6.31707 3.63954Z"
        stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path fillRule="evenodd" clipRule="evenodd"
        d="M19.0002 14.5605C19.0002 13.1029 17.8107 11.921 16.3426 11.921C14.8735 11.921 13.6831 13.1029 13.6831 14.5605C13.6831 16.0181 14.8735 17.2 16.3426 17.2C17.8107 17.2 19.0002 16.0181 19.0002 14.5605Z"
        stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg></span>
                                    <span className="text-[17px] font-nunito">Đơn hàng: {order?.code}</span>
                                </div>
                                {
                                    order?.order_item?.map((order_item,idx) => (
                                        <div className="mb-3" key={idx}><ItemByHistory id={order_item?.id} image={order_item?.product_image} name={order_item?.product_name} price={order_item?.product_price} quantity={order_item?.quantity}/></div>

                                    ))
                                }
                            </div>
                        ))
                    }


                </div>
            </div>

        </div>
    )

}
export default HistoryBy