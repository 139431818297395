import React, {Suspense, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './css/main.css';
import {route_in} from "./routes";
import {StateProvider} from "./contexts/StateContext";
import 'swiper/css';
import 'swiper/css/pagination';

function App() {

  return (
      <div className="wrapper-app relative">
          <Router>
              <StateProvider>
                  <Routes>
                      {route_in.map((route, index) => (
                          <Route
                              key={index}
                              path={route.path}
                              element={<route.component />}
                          />
                      ))}
                  </Routes>
              </StateProvider>
          </Router>


      </div>
  );
}

export default App;
