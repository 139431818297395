import {image} from "../../assets/image";
import {Link} from "react-router-dom";
import {path} from "../../routes";


import {dataPage} from "../../constants/data";
import {useNavigate} from "react-router-dom";


const NavBottom = () => {

    const navigate = useNavigate();

    const handleClickQr = () => {
        // Toast({type: 'warning', message: 'Tính năng đang phát triển'})
        navigate('/gift')
    }


    return (
        <div className={"nav-bottom  fixed bottom-0 left-0 w-full"}>

            <div className=" flex justify-center items-center w-full bg ">
                <div className="nav-bottom-centent w-full max-w-[768px]  h-[80px] relative "
                     style={{
                         background: `url(${image.bg_bottom})`,
                         backgroundSize: '100% 100%',
                         backgroundRepeat: 'no-repeat',
                         filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.5))'
                     }}>
                    {
                        dataPage.map((item, index) => {
                            const isHomePage = item.path === '/';
                            const currentPath = window.location.pathname;
                            const matchHomePage = isHomePage && currentPath === item.path;
                            const matchOtherPage = !isHomePage && (currentPath === item.path || currentPath.startsWith(`${item.path}/`));

                            return (
                                <Link key={index} to={item.path}
                                      className="nb-item flex items-center justify-center flex-col">
                                    <img src={matchHomePage || matchOtherPage ? item.image_active : item.image}
                                         alt={''}/>
                                    <div className="text-center text-sm font-bold mt-1"
                                         style={{color: matchHomePage || matchOtherPage ? '#9F0180' : '#0C1A30'}}>{item.name}</div>
                                </Link>
                            );
                        })
                    }

                    <div onClick={handleClickQr}
                          className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 size-[90px] flex justify-center items-center p-2">
                      <div className={'size-[70px]  rounded-full shadow flex justify-center items-center bg-[#9F0180]'}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#FFFF    "
                               className="bi bi-gift-fill" viewBox="0 0 16 16">
  <path
      d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A3 3 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zm6 4v7.5a1.5 1.5 0 0 1-1.5 1.5H9V7zM2.5 16A1.5 1.5 0 0 1 1 14.5V7h6v9z"/>
</svg>
                      </div>
                    </div>

                </div>
            </div>


        </div>
    )

}
export default NavBottom;