import Home from "../pages/home";
import Login from "../pages/login";
import ChildCareLibrary from "../pages/ChildCareLibrary/index";
import Notification from "../pages/notification/index";
import Account from "../pages/account/index";
import Cart from "../pages/cart/index";
import Voucher from "../pages/voucher/index";
import MyVoucher from "../pages/voucher/MyVoucher";
import SystemVoucher from "../pages/voucher/SystemVoucher";
import ClassMember from "../pages/class_member/index";
import PostBabyDetail from "../component/post_baby/PostBabyDetail";
import ResultSearch from "../pages/result_search/index";
import HistoryBuy from "../pages/history_by/index";
import DefaultLayout from "../component/defaultLayout/index";
import Address from "../pages/address/index";
import CreateAddress from "../pages/address/createAddress";
import PostDetail from "../pages/post_detail/index";
import AccountUpdate from "../pages/account/update";
import ProductDetail from "../pages/product_detail/index";
import Buy from "../pages/buy";

import  {path} from "./data";
import Point from "../pages/point";
import Policy from "../pages/policy";
import MyQrCode from "../pages/my_qr_code";
import Gift from "../pages/gift";
import ExchangeGift from "../pages/gift/ exchangeGift";
import ListExchangeGift from "../pages/gift/listExchangesGift";

const routes = [
    {
        path: path.home,
        name: 'Home',
        component: Home
    },
    {
        path: path.buy,
        name: 'PostBaby',
        component: Buy
    },
    {
        path: path.child_care_library,
        name: 'ChildCareLibrary',
        component: ChildCareLibrary
    },
    {
        path: path.notifications,
        name: 'Notifications',
        component: Notification
    },
    {
        path: path.account,
        name: 'Account',
        component: Account
    },
];

const route_in = [
    {
        path: path.cart,
        name: 'Cart',
        component: Cart
    },
    {
        path: path.voucher,
        name: 'Voucher',
        component: Voucher
    },
    {
        path: path.myVoucher,
        name: 'My Voucher',
        component: MyVoucher
    },
    {
        path: path.systemVoucher,
        name: 'System Voucher',
        component: SystemVoucher
    },
    {
        path: path.class_member,
        name: 'Class Member',
        component: ClassMember
    },
    {
        path: `${path.post_default}/:id`,
        name: 'Post Default',
        component: PostBabyDetail
    },
    {
        path: path.result_search,
        name: 'Result Search',
        component: ResultSearch
    },
    {
        path: path.history_buy,
        name: 'History Buy',
        component: HistoryBuy
    },
    {
        path: '*',
        name: 'Home',
        component: DefaultLayout
    },
    {
        path: path.login,
        name: 'Login',
        component: Login
    },
    {
        path: path.change_address,
        name: 'Address',
        component: Address
    },
    {
        path: path.create_address,
        name: 'Create Address',
        component: CreateAddress
    },
    {
        path: `${path.notification_detail}/:id`,
        name: 'Notification Detail',
        component: PostDetail
    },
    {
        path: path.update_customer,
        name: 'Cập nhật người dùng',
        component: AccountUpdate
    },
    {
        path: `${path.chi_tiet_san_pham}/:id`,
        name: 'ProductDetail',
        component: ProductDetail
    }, {
        path: path.point,
        name: 'Point',
        component: Point
    },{
        path: path.policy,
        name: 'Policy',
        component: Policy
    },{
        path: path.my_qr,
        name: 'My Qr',
        component: MyQrCode
    },
    {
        path: path.gift,
        name: 'Gift',
        component: Gift
    },
    {
        path: path.exchange_gift,
        name: 'Gift',
        component: ExchangeGift
    },
    {
        path: path.history_gift,
        name: 'history exchange gift',
        component: ListExchangeGift
    },
];

export { routes, route_in };
