// defaultLayout.js
import React, {Suspense} from 'react';
import { Routes, Route } from 'react-router-dom';
import { routes } from '../../routes/index';

import NavBottom from '../navbottom/index';

function DefaultLayout() {
    return (
        <div className="w-full pb-[100px]">

            <Routes>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={<route.component />}
                    />
                ))}
            </Routes>


            <NavBottom />
        </div>
    );
}

export default DefaultLayout;
