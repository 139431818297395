import QRCode, {QRCodeSVG} from 'qrcode.react';
import HeaderTitle from "../../component/header_title";
import {Link} from "react-router-dom";
import {path} from "../../routes/data";
import {useEffect, useState} from "react";
function MyQrCode() {
    const [phone, setPhone] = useState('')
    const qrCodeStyle = {
        width: '100%',
        height: 'auto',
        aspectRatio:'1/1',
        objectFit:'content'
    };
    useEffect(() => {
        const storedPhone = localStorage.getItem('phone');
        if (storedPhone) {
            setPhone(storedPhone);
        }
    }, []);
    return (
        <div>
            <div className='wrapp-header-title text-center py-3 sticky top-0 z-50'>
                <div className="relative">
                    <div className="text-white text-xl font-bold " >Qrcode cuả tôi  </div>
                    <div className="absolute top-1/2  -translate-y-1/2 px-3" >
                        <Link to={path.account} className={'p-3'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                                <path d="M3.74804 6.97146L9 1.45436L7.61553 0L0 8L7.61553 16L9 14.5456L3.74804 9.02854L2.93744 8.00008L3.74804 6.97146Z" fill="#E8FDFF"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={'mt-48 px-5'} >
                <QRCode
                    value={phone}
                    renderAs="canvas"
                    imageSettings={{
                        excavate: true,
                    }}
                    style={qrCodeStyle}
                />
            </div>

        </div>
    );
}

export  default  MyQrCode;