import {useBack} from "../utili";

const HeaderTitle = ({title}) => {

    const back=useBack();
    return (
        <div className='wrapp-header-title text-center py-3 sticky top-0 z-50'>
           <div className="relative">
               <div className="text-white text-xl font-bold " >{title}</div>
               <div className="absolute top-1/2  -translate-y-1/2 p-3" onClick={back}>
                   <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                       <path d="M3.74804 6.97146L9 1.45436L7.61553 0L0 8L7.61553 16L9 14.5456L3.74804 9.02854L2.93744 8.00008L3.74804 6.97146Z" fill="#E8FDFF"/>
                   </svg>
               </div>
           </div>
        </div>
    )
}

export default HeaderTitle;