import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {useEffect} from "react";

function useBack() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return goBack;
}
const formatCurrency = (amount) => {
    const numericAmount = Number(amount);

    if (isNaN(numericAmount)) {
        return 'null';
    }
    return amount.toLocaleString('vi-VN');
};
//Tổng tiền
const calculateTotalPrice = (products) => {
    let totalPrice=0;
    products.forEach((product) => {
        totalPrice += product?.product_price * product?.quantity;
    });
    return totalPrice;

}
const Toast = ({ type, message }) => {
    const toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });

    if (type === 'error') {
        toast.fire({
            icon: 'error',
            title: message
        });
    } else if (type === 'success') {
        toast.fire({
            icon: 'success',
            title: message
        });
    } else if (type === 'warning') {
        toast.fire({
            icon: 'warning',
            title: message
        });
    }
};




export {useBack,formatCurrency,calculateTotalPrice,Toast};
