const path = {
    home: '/',
    buy: '/buy',
    chi_tiet_san_pham: '/chi-tiet-san-pham',
    cart: '/cart',
    voucher: '/voucher',
    myVoucher: '/my-voucher',
    systemVoucher: '/system-voucher',
    class_member: '/class-member',
    child_care_library: '/child-care-library',
    post_default: '/post-default',
    result_search: '/result-search',
    history_buy: '/history-buy',
    notifications: '/notifications',
    account: '/account',
    login: '/login',
    change_address: '/change-address',
    create_address: '/create-address',
    notification_detail: '/notification_detail',
    update_customer: '/update-customer',
    point: '/point',
    policy: '/policy',
    my_qr: '/my-qr',
    gift:'/gift',
    exchange_gift:'/exchange-gift',
    history_gift:'/history-gift',
};

export {path}