import {Link} from "react-router-dom";
import {path} from '../../routes/data'
import {url} from "../../api/index"
const PostBaby = ({id,title,content,image,category_name}) => {
    return (
        <Link to={`${path.post_default}/${id}`} className='w-full care-library relative '>
            <div className="w-full relative">
                <img src={url+image} alt="" className='absolute w-full h-full object-cover rounded-lg top-0 left-0'/>
                <div className="pt pt-[50%]"></div>
            </div>
            <div className="mt-3 ">
                <div className="text-sm font-medium text-[#06070C] mb-3 line-clamp-2">
                    {title}
                </div>
                <div className="text-xs line-clamp-3 font-normal ">
                    <div dangerouslySetInnerHTML={{ __html:content }} />

                </div>
            </div>
            <div className="absolute top-0 right-[-10px] opacity-90 text-sm   font-light font-nunito text-white px-5 bg-[#900011] rounded-full">{category_name}</div>
        </Link>
    )
}
export default PostBaby;