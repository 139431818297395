import {image} from "../../assets/image";
import {useStateContext} from "../../contexts/StateContext";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {path} from "../../routes/data";
import {url} from "../../api/index"
function Account() {
    const {setNumberpage, numberPage,user} = useStateContext();



    return (
        <div className={'wrapp-account  flex flex-col'}>
            <div className='wrapp-header-title text-center py-3 sticky top-0 z-50'>
                <div className="relative">
                    <div className="text-white text-xl font-bold " >Tài khoản</div>
                    <div className="absolute top-1/2  -translate-y-1/2 px-3" >
                        <Link to={path.home} className={'p-3'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                                <path d="M3.74804 6.97146L9 1.45436L7.61553 0L0 8L7.61553 16L9 14.5456L3.74804 9.02854L2.93744 8.00008L3.74804 6.97146Z" fill="#E8FDFF"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="px-5">
                <div className="home-head-content py-5 ">
                    <div className="flex gap-3 items-center">
                        {user?.avatar && user.avatar !== null && user.avatar !== '' ? (
                            <img src={url + user.avatar}
                                 className="avatar-home object-cover size-[45px] rounded-full" alt=""/>
                        ) : (
                            <img src={image.avatar_default}
                                 className="avatar-home object-cover size-[45px] rounded-full" alt=""/>
                        )}
                        <div className="avatar-info ">
                            <div className="info-name text-sm font-medium">{user?.first_name} {user?.last_name}</div>
                            <div className="info-point text-xs flex gap-1 font-normal">Điểm
                                tích: <strong>{user?.total_earned_points-user?.total_spent_points}</strong> điểm <span><img src={image.icon_point} alt=""/></span></div>
                        </div>
                        <div className="ms-auto">
                            <Link to={path.update_customer} className={'rounded-lg bg-[#3669C9] px-3 py-2 text-white text-sm'}>Cập nhật</Link>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <div className="flex justify-between items-center mb-3">
                        <span className={'text-[15px] font-roboto text-[#45579A]'}>Danh sách đơn hàng của tôi</span>
                        <Link to={path.history_buy}
                              className={'bg-[#3669C9] rounded-lg text-[9px] w-[57px] font-roboto text-center text-white'}>xem
                            đơn</Link>
                    </div>
                    <div className="flex justify-between items-center mb-3">
                        <Link to={path.my_qr} className={'text-[15px] font-roboto text-[#45579A]'}>QR code của tôi</Link>
                    </div>
                    <div className="flex justify-between items-center mb-3">
                        <Link to={path.policy} className={'text-[15px] font-roboto text-[#45579A]'}>Điều khoản và chính sách</Link>
                    </div>
                    <div className="flex justify-between items-center mb-3">
                        <span className={'text-[15px] font-roboto text-[#45579A]'}>Nhận thông tin khuyến mãi</span>
                        <span
                            className={'relative bg-[#3669C9] rounded-lg text-[9px] w-[57px] font-roboto text-center text-white'}>Tắt <span
                            className={'absolute top-0 left-0 size-[14px] rounded-full bg-[#D9D9D9]'}></span></span>
                    </div>
                </div>
            </div>
            <div className="flex justify-center w-full  fixed bottom-[100px] ">
                <img src={image.account_b} className={'w-[231px] object-cover'} alt=""/>
            </div>

        </div>
    )
}

export default Account;