import { useBack } from "../../component/utili";
import Product_Item from "../../component/product_item";
import React, { useEffect, useState } from "react";
import apis, { request_api } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { path } from "../../routes/data";

function Buy() {
    const [categoryData, setCategoryData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [keySearch, setKeySearch] = useState(null);
    const [categoryId, setCategoryId] = useState('');
    const back = useBack();
    const navigate = useNavigate();
    const { categoryParam } = useParams();

    const getDataCategory = async () => {
        try {
            const response = await request_api({ url: apis.getCategory(), method: 'get' });
            setCategoryData(response?.data?.custom_collections);
        } catch (error) {
            console.error("Error occurred while fetching data:", error);
        }
    };


    const getProductToCategoryData = async (categoryId) => {
        try {
            const data = new FormData();
            data.append('categoryId', categoryId);
            const response = await request_api({ url: apis.getProductToCategory(), method: 'post', data: data });
            setProductData(response?.data?.products);
        } catch (error) {
            console.error("Error occurred while fetching data:", error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(path.result_search, { state: { keySearch } });
    };

    const handlePath = (id) => {
        navigate(`${path.buy}?categoryParam=${id}`);
        setCategoryId(id);
    };

    useEffect(() => {
        getDataCategory();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const categoryParam = params.get('categoryParam');
        if (categoryParam) {
            setCategoryId(categoryParam);
        }
    }, [categoryParam]);

    useEffect(() => {
        if (categoryId) {
            getProductToCategoryData(categoryId);
        }
    }, [categoryId]);



    return (
        <div className="wrapper-buy">
            <div className="buy-header text-white px-3 py-3 sticky top-0 z-50 ps-0">
                <div className="flex justify-between items-center gap-5">
                    <div className="p-3" onClick={back}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                            <path
                                d="M3.74804 6.97146L9 1.45436L7.61553 0L0 8L7.61553 16L9 14.5456L3.74804 9.02854L2.93744 8.00008L3.74804 6.97146Z"
                                fill="#E8FDFF"
                            />
                        </svg>
                    </div>
                    <div className="w-full">
                        <div className="flex items-center gap-3 buy-search-pro rounded-full w-full h-[35px]">
                            <form className="w-full" onSubmit={handleSubmit}>
                                <div className="flex items-center w-full">
                                    <button>
                                        <div className="flex h-full items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                <path
                                                    d="M12.772 11.7704L15.8058 14.8035L14.8035 15.8058L11.7704 12.772C10.6418 13.6767 9.23809 14.1687 7.79167 14.1667C4.27267 14.1667 1.41667 11.3107 1.41667 7.79169C1.41667 4.27269 4.27267 1.41669 7.79167 1.41669C11.3107 1.41669 14.1667 4.27269 14.1667 7.79169C14.1687 9.2381 13.6767 10.6418 12.772 11.7704ZM11.351 11.2448C12.25 10.3204 12.752 9.08116 12.75 7.79169C12.75 5.05185 10.5308 2.83335 7.79167 2.83335C5.05184 2.83335 2.83334 5.05185 2.83334 7.79169C2.83334 10.5308 5.05184 12.75 7.79167 12.75C9.08114 12.7521 10.3203 12.25 11.2448 11.3511L11.351 11.2448Z"
                                                    fill="#09121F"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                    <input
                                        type="search"
                                        className="ms-2 flex-grow flex-1 bottom-0 w-full"
                                        name="searchp"
                                        placeholder="Tìm kiếm sản phẩm"
                                        onChange={(e) => setKeySearch(e.target.value)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="buy-content mt-4 px-3">
                <div className="buy-title">Danh mục sản phẩm</div>
                <div
                    className="buy-subcategories rounded-lg bg-[#FFCBF4] p-4 gap-2 mt-5 overflow-x-auto grid grid-rows-5 grid-flow-col">
                    {categoryData?.map((item) =>
                        item.id == categoryId ? (
                            <div
                                key={item.id}
                                onClick={() => handlePath(item.id)}
                                className="subcate-item text-nowrap text-xs text-white bg-[#960478] inline-block px-5 py-2 rounded-full opacity-90 text-center"
                            >
                                {item.name} {categoryParam}
                            </div>
                        ) : (
                            <div
                                key={item.id}
                                onClick={() => handlePath(item.id)}
                                className="text-nowrap subcate-item text-xs text-white bg-[#FF8DE7] inline-block px-5 py-2 rounded-full opacity-90 text-center"
                            >
                                {item.name}
                            </div>
                        )
                    )}
                </div>
                <div className="list-prodyct-by mt-10 grid grid-cols-2 gap-3">
                    {productData?.map(
                        (product, i) =>
                            product?.image?.src && <Product_Item product={product} key={i} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Buy;
