import HeaderTitle from "../../component/header_title";
import {Link} from "react-router-dom";
import ItemSystemVoucher from "./ItemSystemVoucher";
import {useStateContext} from "../../contexts/StateContext";

function SystemVoucher() {
    const {systemVouchers}=useStateContext();
    return (
        <div className={''}>
            <HeaderTitle title={'Voucher của tôi'}/>
            <div className="list-vouchers mt-5 px-5">
                <div className="mb-3">
                    <div className="flex items-center">
                        <div className="text-baseb font-semibold">Voucher hệ thống</div>
                        <Link to={''} className={'ms-auto text-sm font-light'}>Tất cả</Link>

                    </div>
                    <div className="mt-3">
                        {
                            systemVouchers?.map((item,idx)=>(
                                <ItemSystemVoucher key={idx} title={item?.title} content={item?.content} point={item?.point} image={
                                    item?.image
                                } />
                            ))
                        }
                    </div>


                </div>
            </div>

        </div>
    )
}

export default SystemVoucher;