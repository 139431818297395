import {api_customers, request_url, url} from "../../api";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {path} from "../../routes/data";

const ItemVoucher = ({title,content,date,id,image}) => {
    const navigate=useNavigate();


    const handleCart=()=>{
        navigate(path.cart,{state:{voucherId:id}})
    }
  return (
      <div className="wrap-item-voucher border-2 border-[#C7C9D] rounded-lg  mb-3 h-[120px]">
          <div className="w-full flex items-center h-full ">
              <div className="p-3"><img src={url + image} alt="" className={'w-[100px] h-[100px] object-cover'}/></div>
              <div className="border-dashed border-2 border-[#C7C9D9] h-full "></div>
              <div className="p-3 font-mont flex-grow h-full">
                  <div className="text-base font-semibold  text-[#28293D] flex">
                      <span>{title}</span>
                      <span className={'ms-auto'}><img src={image.icon_i} alt=""/></span>
                  </div>
                  <div className={'text-sm text-[#8F90A6] pe-10'}>{content}</div>
                  <div className="flex items-center">
                      <span className={'text-xs text-[#8F90A6]'}>Ngày hết hạn : {date}</span>
                      <span className={'ms-auto  font-nunito rounded bg-[#D801AB] text-white text-[15px] px-2 py-1'} onClick={handleCart}>Sử dụng</span>
                  </div>
              </div>
          </div>
      </div>

  )
}
export default ItemVoucher;