import axios from "axios";


export const  url='https://cmsleuyen.winwingroup.vn/';
// export const  url='http://127.0.0.1:8000/';


const apis = {
    getCategory: () => url+'api/sapo/category',
    getProductToCategory: () =>  url+`api/sapo/product-to-category`,
    getProductSearch: () => url+`api/sapo/product-search`,
    getProductDetail: () =>url+ `api/sapo/product-detail`,
    getPostDetail: (id)=>url+`api/articles/post-detail/${id}`,
    getPostByCategory:() =>url+`api/categories/post-by-category`,
    searchArticlesByName: () => url+`api/articles/post-search`,
    point_content: () => url+`api/feature/point-content`,
    policy_content: () => url+`api/feature/policy-content`,
    getDataHome:()=>url+`api/home`,
    getListGift:()=>url+`api/gift/list-data`,
    exchangeGift:()=>url+`api/gift/change`,
    listHistoryGift:()=>url+`api/gift/list-gift`,
}

const api_customers ={
    login: () => url+`api/customer/login`,
    getAddress: () => url+`api/customer/address`,
    getProvince: () => url+`api/province`,
    getDistrict: (provinceId) => url+`api/district/${provinceId}`,
    getWard: (districtId) => url+`api/ward/${districtId}`,
    createAddress: () => url+`api/customer/address`,
    getAddressId: (id) => url+`api/customer/address/${id}`,
    systemVoucher: () => url+`api/customer/all_voucher`,
    changeVoucher: () => url+`api/customer/getchangeVoucher`,
    redeemVoucher: (id) => url+`api/customer/redeemVoucher/${id}`,
    voucherCustomerDetail: (id) => url+`api/customer/voucher-customer-detail/${id}`,
    getListArticle: ()=>url+`api/categories`,
    getVoucherRank:()=>url+`api/customer/voucher-rank`,
    order:()=>url+`api/customer/order`,
    getNotification:()=>url+`api/customer/notification`,
    getNotificationDetail:(id)=>url+`api/customer/notification/${id}`,
    getOrders:()=>url+`api/customer/orders`,
    updateCustomer:()=>url+`api/customer/update-customer`,
}

const token_customer=localStorage.getItem('token_customer')
const   request_api = async ({url, method, data}) => {
    try {
        const headers = {
            "Content-Type": "application/json",
        };

        const config = {method, url, data, headers};
        const res = await axios(config);

        return res.data;

    } catch (error) {
        console.log(error);

    }
};

const request_url = async ({ url, method, data }) => {
    try {
        const headers = {
            "Authorization": `Bearer ${token_customer}`, // Assuming token is provided
        };

        const config = { method, url, data, headers };
        if (!(data instanceof FormData)) {
            headers["Content-Type"] = "application/json";
        }

        const res = await axios(config);
        return res.data;

    } catch (error) {
        console.log(error);
        throw error;  // Re-throw the error after logging it to allow for proper error handling
    }
};
const request_res = async ({url, method, data}) => {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        const config = {method, url, data, headers};
        const res = await axios(config);
        return res.data;

    } catch (error) {
        console.log(error);
    }
};

export default apis;

export {request_api,request_url,api_customers,request_res};
