import HeaderTitle from "../../component/header_title";
import {useEffect, useState} from "react";
import apis, {request_url} from "../../api";
import {useStateContext} from "../../contexts/StateContext";

const ListExchangeGift = () => {

    const [listData, setListData] = useState([]);
    const [status, setStatus] = useState(1);
    const {user} = useStateContext();

    const getDataHistoryChangeGift = async () => {

        const res = await request_url({
            url: apis.listHistoryGift(), method: 'post', data: {
                phone: user?.phone,
            }
        });
        console.log(res)
        setListData(res?.data)


    }
    useEffect(() => {
        if (user) {
            getDataHistoryChangeGift();
        }
    }, [user]);

    const formatDateString=(dateString)=> {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based.
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }



    return (
        <div className="">
            <HeaderTitle title={"Lịch sử đổi quà"}/>
            <div className="px-5">


                <div className="mt-7">
                    {

                        listData?.map((item, idx) => (
                            <div className="mb-3 relative" key={idx} >
                                <div className={'absolute text-xs font-bold right-0 top-0'}>
                                    {
                                        item?.status === 0 ? <div className={'bg-blue-600 text-white px-2 py-1 rounded'}>Chờ xác nhận</div> : ''
                                    }
                                    {
                                        item?.status === 1 ? <div className={'bg-green-600 text-white px-2 py-1 rounded'}>Đã xác nhận</div> : ''
                                    }
                                    {
                                        item?.status === 2 ? <div className={'bg-emerald-600 text-white px-2 py-1 rounded'}>Đã giao</div> : ''
                                    }
                                    {
                                        item?.status === 3 ? <div className={'bg-red-600  text-white px-2 py-1 rounded'}>Đã hủy</div> : ''
                                    }
                                </div>
                                <div className="item-pro-cart flex gap-4 font-nunito mb-5">
                                    <div className="">
                                        <img src={item.gift_image} alt={''}
                                             className={'w-[89px] h-[93px] object-cover rounded-lg border-2 border-[#CF01A4]'}/>
                                    </div>
                                    <div className="flex-1 ">
                                        <div className={'text-[15px]'}>
                                            <div className=" text-[#333333] font-semibold ">
                                                {item?.gift_name}
                                            </div>
                                            <div className="">
                                                {item?.phone}
                                            </div>
                                            <div className="">
                                                {item?.name}
                                            </div>
                                            <div className="">
                                                {item?.address}
                                            </div>
                                            <div className="">
                                                Ngày đổi: {formatDateString(item?.created_at)}
                                            </div>


                                        </div>

                                    </div>

                                </div>
                            </div>

                        ))
                    }


                </div>
            </div>

        </div>
    )

}
export default ListExchangeGift