import  {api_customers, request_url, url} from "../../api";
import {useEffect, useState} from "react";
import {Toast} from "../../component/utili";
import Swal from "sweetalert2";
import {useStateContext} from "../../contexts/StateContext";

const ItemSystemVoucher = ({title, content, point, id,image}) => {

    const {setSystemVouchers, setChangedVoucher, setVocherRanks, setPoint, user} = useStateContext();

    const redeemVoucher = async () => {

        const res = await request_url({url: api_customers.redeemVoucher(id), method: 'get'});
        console.log(res)
        if (res?.status) {
            console.log(res);
            Swal.mixin({
                toast: true,
                position: "top",
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            }).fire({
                icon: 'success',
                text: 'Đổi thành công',
                showConfirmButton: false,
                timer: 1500
            })

            setPoint(user?.total_earned_points - user?.total_spent_points - point);

            // Cập nhật danh sách voucher hệ thống
            await getSystemVoucher();

            // Cập nhật danh sách voucher đã đổi
            await getChangedVoucher();

            // Cập nhật danh sách hạng voucher
            await getVoucherRanks();
        }else  if (!res?.status) {
            Swal.mixin({
                toast: true,
                position: "top",
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            }).fire({
                icon: 'error',
                text: 'Bạn không đủ điểm đổi voucher',
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const getSystemVoucher = async () => {
        const res = await request_url({url: api_customers.systemVoucher(), method: 'get'});
        if (res?.status) {
            console.log(res);
            setSystemVouchers(res?.pending_vouchers);
        }
    }

    const getChangedVoucher = async () => {
        const res = await request_url({url: api_customers.changeVoucher(), method: 'get'});
        if (res?.status) {
            setChangedVoucher(res?.pending_vouchers);
        }
    }

// Lấy danh sách hạng voucher từ API và cập nhật state
    const getVoucherRanks = async () => {
        try {
            const res = await request_url({url: api_customers.getVoucherRank(), method: 'get'});
            if (res?.status) {
                setVocherRanks(res?.pending_vouchers);
            }
        } catch (e) {
            console.log(e);
        }
    }


    return (
        <div className="wrap-item-voucher border-2 border-[#C7C9D] rounded-lg  mb-3 h-[120px]">
            <div className="w-full flex items-center h-full ">
                <div className="p-3"><img src={url +image} alt="" className={'w-[100px] h-[100px] object-cover'}/></div>
                <div className="border-dashed border-2 border-[#C7C9D9] h-full "></div>
                <div className="p-3 font-mont flex-grow h-full">
                    <div className="text-base font-semibold  text-[#28293D] flex">
                        <span>{title}</span>
                        <span className={'ms-auto'}><img src={image.icon_i} alt=""/></span>
                    </div>
                    <div className={'text-sm text-[#8F90A6] pe-10'}>{content}</div>
                    <div className="flex items-center">
                        <span className={'text-xs text-[#8F90A6]'}>Số điểm đổi:<span
                            className={'text-[#00408B] font-bold mx-1'}>{point}</span>điểm</span>
                        <span className={'ms-auto  font-nunito rounded bg-[#00408B] text-white text-[15px] px-2 py-1'}
                              onClick={redeemVoucher}>Đổi ngay</span>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ItemSystemVoucher;